<script>
import Spinner from "@/components/ui/Spinner";
import { mapGetters } from "vuex";
import userService from "@/services/user";
import helperService from "@/services/helper";
import ModalAgenda from "@/components/appointment/ModalAgenda.vue";
import medicalSpecialtyService from "@/services/medical-specialty";
import institutionService from "@/services/institution";
import agendaService from "@/services/agenda";
import pagination from "@/mixins/pagination";

export default {
  name: "AgendasView",

  mixins: [pagination],

  components: {
    Spinner,
    ModalAgenda,
  },

  data() {
    return {
      doctors: [],
      medicalSpecialties: [],
      institutions: [],
      agendas: [],
      selectedDoctorId: "",
      selectedInstitutionId: "",
      selectedMedicalSpecialtyId: "",
      isLoading: false,
      isModalVisible: false,
      selectedAgenda: {},
    };
  },

  computed: {
    ...mapGetters(["isAdmin", "isDoctor", "user"]),
  },

  watch: {
    selectedDoctorId() {
      this.getAgendas();
    },
    selectedInstitutionId() {
      this.getAgendas();
    },
    selectedMedicalSpecialtyId() {
      if (this.selectedDoctorId && this.selectedInstitutionId) {
        this.getAgendas();
      }
    },
  },

  created() {
    this.getDoctors();
    this.getMedicalSpecialties();
    this.getInstitutions();
    this.getAgendas();

    this.$bus.$on("agenda-created", (createdAgenda) => {
      this.agendas.unshift(createdAgenda);
    });

    this.$bus.$on("agenda-updated", (updatedAgenda) => {
      const index = this.agendas.findIndex(
        (agenda) => agenda._id === updatedAgenda._id
      );
      this.agendas[index] = updatedAgenda;
    });

    this.$bus.$on("agenda-deleted", (deletedAgenda) => {
      const index = this.agendas.findIndex((agenda) => {
        return agenda._id === deletedAgenda._id;
      });
      this.$delete(this.agendas, index);
    });
    if (this.isDoctor) {
      this.selectedDoctorId = this.user._id;
    }
  },

  methods: {
    async getDoctors() {
      this.isLoading = true;
      try {
        const { docs } = await userService.getDoctors();
        this.doctors = docs;
      } finally {
        this.isLoading = false;
      }
    },

    async getMedicalSpecialties() {
      this.isLoading = true;
      try {
        const medicalSpecialties =
          await medicalSpecialtyService.getMedicalSpecialties();
        this.medicalSpecialties = medicalSpecialties;
      } finally {
        this.isLoading = false;
      }
    },

    async getInstitutions() {
      this.isLoading = true;
      try {
        const institutions = await institutionService.getInstitutions();
        this.institutions = institutions;
      } finally {
        this.isLoading = false;
      }
    },

    async getAgendas() {
      this.isLoading = true;
      try {
        let query = {};

        this.selectedDoctorId && (query.doctor = this.selectedDoctorId);
        this.selectedInstitutionId &&
          (query.institution = this.selectedInstitutionId);
        this.selectedMedicalSpecialtyId &&
          (query.medicalSpecialties = this.selectedMedicalSpecialtyId);

        const aggregationQuery = {
          ...query,
          offset: this.pagination.offset,
          limit: this.pagination.limit,
        };

        const res = await agendaService.getAgendas(aggregationQuery);
        this.agendas = res.docs;
        this.pagination.total = res.total;
      } finally {
        this.isLoading = false;
      }
    },

    updateAgenda(agenda) {
      this.selectedAgenda = agenda;
      this.isModalVisible = true;
    },

    lockAgenda(agenda) {
      this.$confirm(`¿Desea bloquear la agenda?`, "Bloquear", {
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        type: "warning",
      })
        .then(() => {
          agendaService.lock(agenda._id).then(() => {
            const index = this.agendas.findIndex(
              (elem) => elem._id === agenda._id
            );

            const modifiedAgenda = {
              ...this.agendas[index],
              lockedAt: new Date(),
            };

            this.$set(this.agendas, index, modifiedAgenda);
          });
        })
        .catch(() => {});
    },

    unlockAgenda(agenda) {
      agendaService.unlock(agenda._id).then(() => {
        const index = this.agendas.findIndex((elem) => elem._id === agenda._id);
        const modifiedAgenda = { ...this.agendas[index] };
        delete modifiedAgenda.lockedAt;

        this.$set(this.agendas, index, modifiedAgenda);
      });
    },

    async generateSlots(agenda) {
      await agendaService.generateSlots(agenda._id);
    },

    getFullName(user) {
      return helperService.getFullName(user);
    },

    getMedicalSpecialtiesNames(agenda) {
      return agenda.medicalSpecialties?.map((m) => m?.name).join(", ") || "N/A";
    },

    showModal() {
      this.isModalVisible = true;
    },

    closeModal() {
      this.selectedAgenda = {};
      this.isModalVisible = false;
    },

    onPageChange() {
      this.getAgendas();
    },
  },
};
</script>

<template lang="pug">
  section
      header.headline
        .headline__title
          h1
            | Agendas
            small(v-if="!isLoading && pagination.total")  ({{ pagination.total }})

          hr
        .headline__actions
          el-select(
              v-model="selectedInstitutionId"
              placeholder="Institución"
              filterable
              clearable
              default-first-option
          )
            el-option(
                v-for="insitution in institutions"
                :key="insitution._id"
                :label="insitution.name"
                :value="insitution._id"
            )

          el-select(
              v-model="selectedDoctorId"
              placeholder="Médico"
              filterable
              clearable
              default-first-option
          )
            el-option(
              v-for="doctor in doctors"
              :key="doctor._id"
              :label="`${getFullName(doctor)}`"
              :value="doctor._id"
            )

          el-select(
              v-model="selectedMedicalSpecialtyId"
              placeholder="Especialidad"
              filterable
              clearable
              default-first-option
          )
            el-option(
                v-for="medicalSpecialty in medicalSpecialties"
                :key="medicalSpecialty._id"
                :label="medicalSpecialty.name"
                :value="medicalSpecialty._id"
            )

          button.button.button--blue(
            @click='showModal'
          )
              micon(name="add_circle_outline").button__icon
              span.button__text Nueva Agenda

      .box.box--with-subnav
        .box__content--stretch
          spinner(v-if="isLoading")
          h2.no-alerts(v-if="!isLoading && !agendas.length") No se encontraron agendas
          table(v-if="!isLoading && agendas.length")
            thead
              tr
                th Institución
                th Doctor
                th Especialidad
                th Fecha Inicio
                th Fecha Fin
                th Fecha Creación
                th Ventana
                th Pública
                th Estado
                th Acciones
            tbody
              tr(
                v-for='agenda in agendas' :key="agenda._id" @click="updateAgenda(agenda)")
                td {{ agenda.institution.name }}
                td {{ getFullName(agenda.doctor) }}
                td {{ getMedicalSpecialtiesNames(agenda) }}
                td {{ agenda.startDate | formatDate }}
                td {{ agenda.endDate | formatDate }}
                td {{ agenda.createdAt | formatDate }}
                td {{ agenda.windowPeriod }} días
                td {{ agenda.isVisibleByPatient ? "Sí" : "No"}}
                td {{ agenda.lockedAt ? "Bloqueada" : "Activa"}}
                td.actions
                  .actions-container
                    el-dropdown(trigger="click")
                      .button.button--action.el-dropdown-link(@click.stop)
                        micon(name="more_horiz")
                      el-dropdown-menu(slot="dropdown")
                        el-dropdown-item
                          div(@click="updateAgenda(agenda)")
                            micon(name="edit")
                            | Editar Agenda
                        //- el-dropdown-item(v-show="!agenda.lockedAt")
                        //-   div(@click="lockAgenda(agenda)")
                        //-     micon(name="lock")
                        //-     | Bloquear Agenda
                        //- el-dropdown-item(v-show="agenda.lockedAt")
                        //-   div(@click="unlockAgenda(agenda)")
                        //-     micon(name="lock")
                        //-     | Desbloquear Agenda
      pagination(
        :isLoading="isLoading"
        :limit="pagination.limit"
        :total="pagination.total"
        @pagination="setPagination"
      )
                
      modal-agenda(
        v-if='this.isModalVisible'
        :institutions='institutions',
        :selectedAgenda='selectedAgenda',
        @close='closeModal'
      )
</template>

<style lang="scss" scoped>
@import "@/styles/settings/index.scss";
@import "@/styles/tools/index.scss";

tbody .free:hover {
  cursor: pointer;
}

h2 {
  margin-top: 20px;
  text-align: center;
}

.busy,
.available {
  margin-right: 10px;
  padding: 0 5px;
}

.busy {
  p {
    padding: 5px;
    border-radius: 3px;
    display: inline-block;
    background-color: #fef0f0;
    border-color: #fde2e2;
    border: 1px solid #fde2e2;
    color: #f56c6c;
  }
}

.available {
  p {
    padding: 5px;
    border-radius: 3px;
    display: inline-block;
    background-color: #f0f9eb;
    border-color: #e1f3d8;
    border: 1px solid #e1f3d8;
    color: #67c23a;
  }
}

.empty-row {
  td {
    border: none;
  }
}
</style>
