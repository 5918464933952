import api from "./api";

const appointmentService = {};
const endpoint = "/appointments";

appointmentService.getAppointments = function (params) {
  return api.get(endpoint, { params }).then(({ data }) => data);
};

appointmentService.createAppointment = function (appointment) {
  return api.post(endpoint, appointment).then(({ data }) => data);
};

appointmentService.getAppointmentById = function (id) {
  return api.get(`${endpoint}/${id}`).then(({ data }) => data);
};

appointmentService.deleteAppointment = function (id) {
  return api.delete(`${endpoint}/${id}`).then(({ data }) => data);
};

appointmentService.endAppointment = function (id) {
  return api.put(`${endpoint}/${id}/end`).then(({ data }) => data);
};

appointmentService.getAppointmentsByPatient = function ({ patientId }) {
  return api
    .get(`${endpoint}/patient/${patientId}/virtual?`)
    .then(({ data }) => data);
};

appointmentService.cancelAppointment = function (id) {
  return api.delete(`${endpoint}/${id}`).then(({ data }) => data);
};

export default appointmentService;
