<script>
import { add, sub } from "date-fns";
import Spinner from "@/components/ui/Spinner";
import { mapGetters } from "vuex";
import appointmentService from "@/services/appointment";
import debMediaService from "@/services/debmedia";
import helperService from "@/services/helper";
import pagination from "@/mixins/pagination";
import {
  SLOT_ATTENTION_TYPES,
  APPOINTMENTS_STATUS,
} from "@/services/constants";
import { theme } from "@/services/config";
import ModalDerivation from "./ModalDerivation";

export default {
  name: "AdmissionsView",

  mixins: [pagination],

  components: {
    Spinner,
    ModalDerivation,
  },

  data() {
    return {
      appointments: [],
      isModalVisible: false,
      isLoading: false,
      selectedWorkplace: this.$store.state.workplace,
      selectedAppointmentId: undefined,
      currentAppointment: undefined,
      workplaces: [],
      derivableQueues: [],
      theme,
    };
  },

  computed: {
    ...mapGetters(["isAdmin", "isDoctor", "user"]),
  },

  created() {
    this.loadInitialData();
  },

  beforeDestroy() {
    this.stopPolling();
  },

  methods: {
    async loadInitialData() {
      this.isLoading = true;
      await this.getAppointments();
      this.startPolling();

      await this.getWorkplaces();
      await this.getCurrentAppointment();
      await this.getDerivableQueues();
      this.isLoading = false;
    },

    async getAppointments() {
      try {
        const query = {
          startsAt: { $gte: sub(new Date(), { hours: 4 }) },
          endsAt: { $lte: add(new Date(), { hours: 24 }) },
        };

        const aggregationQuery = {
          ...query,
          sort: { startsAt: 1 },
          offset: this.pagination.offset,
          limit: this.pagination.limit,
          populate: ["doctor", "institution", "medicalSpecialty", "patient"],
        };

        const res = await appointmentService.getAppointments(aggregationQuery);
        this.appointments = res.docs;
        this.pagination.total = res.total;
      } catch {
        () => {};
      }
    },

    startPolling() {
      this.pollingInterval = setInterval(() => {
        this.getAppointments();
        this.getCurrentAppointment();
      }, 5000);
    },

    stopPolling() {
      if (this.pollingInterval) {
        clearInterval(this.pollingInterval);
      }
    },

    async getCurrentAppointment() {
      if (
        theme === "ccr" &&
        this.user?.debMediaToken &&
        this.$store.state.workplace
      ) {
        try {
          const currentAppointment =
            await debMediaService.getCurrentAppointment();

          this.currentAppointment = currentAppointment;
        } catch {
          () => {};
        }
      }
    },

    getFullName(user) {
      return helperService.getFullName(user);
    },

    getAttentionTypeName(type) {
      const attentionType = SLOT_ATTENTION_TYPES.find(
        (elem) => elem.value === type
      );
      return attentionType?.name || "N/A";
    },

    getAttentionStatus(status) {
      const attentionStatus = APPOINTMENTS_STATUS.find(
        ({ value }) => status === value
      );

      return attentionStatus?.label || "N/A";
    },

    getMedicalInsurance(user) {
      return user.patient?.medicalInsurance?.value?.name
        ? `${user.patient.medicalInsurance.value.name} ${user.patient.medicalInsurance.plan}`
        : "N/A";
    },

    async getWorkplaces() {
      if (theme === "ccr" && this.user?.debMediaToken) {
        try {
          const workplaces = await debMediaService.getWorkplaces();
          this.workplaces = workplaces;

          if (
            this.selectedWorkplace &&
            !workplaces.some(
              (workplace) => workplace.id === this.selectedWorkplace
            )
          ) {
            this.selectedWorkplace = undefined;
          }
        } catch {
          () => {};
        }
      }
    },

    callAppointment(appointmentId) {
      if (theme === "ccr") {
        if (
          !!this.currentAppointment?._id &&
          appointmentId !== this.currentAppointment?._id
        ) {
          this.$message.error(
            "No puedes ejecutar esta acción. Finaliza el turno activo primero."
          );
          return;
        }
        debMediaService
          .callAppointment({ appointmentId })
          .then((appointment) => {
            this.$message.success("Turno llamado con éxito");
            this.currentAppointment = appointment;

            const index = this.appointments.findIndex(
              (elem) => elem._id === appointment._id
            );
            if (index >= 0) {
              this.$set(this.appointments, index, {
                ...appointment,
                status: "started",
              });
            }
          });
      }
    },

    endAppointment(appointment) {
      if (theme === "ccr") {
        if (appointment._id !== this.currentAppointment?._id) {
          this.$message.error(
            "No puedes ejecutar esta acción. Finaliza el turno activo primero."
          );
          return;
        }
      }

      appointmentService.endAppointment(appointment._id).then(() => {
        this.$message.success("Turno finalizado con éxito");
        this.currentAppointment = undefined;

        const index = this.appointments.findIndex(
          (elem) => elem._id === appointment._id
        );
        if (index >= 0) {
          this.$set(this.appointments, index, {
            ...appointment,
            status: "done",
          });
        }
      });
    },

    async getDerivableQueues() {
      if (
        theme === "ccr" &&
        this.user?.debMediaToken &&
        this.$store.state.workplace
      ) {
        try {
          this.derivableQueues = [];
          const derivableQueues = await debMediaService.getDerivableQueues();
          this.derivableQueues = derivableQueues;
        } catch {
          () => {};
        }
      }
    },

    showModal(appointmentId) {
      this.selectedAppointmentId = appointmentId;
      this.isModalVisible = true;
    },

    closeModal() {
      this.selectedAppointmentId = undefined;
      this.isModalVisible = false;
    },

    appointmentDerivated(derivatedAppointment) {
      const index = this.appointments.findIndex(
        (elem) => elem._id === derivatedAppointment._id
      );

      this.$set(this.appointments, index, derivatedAppointment);

      this.currentAppointment = undefined;
    },

    isCurrentAppointment(appointmentId) {
      return appointmentId === this.currentAppointment?._id;
    },

    getAppointmentClass(appointment) {
      if (this.isCurrentAppointment(appointment._id)) {
        return "active";
      }

      switch (appointment.status) {
        case "ready":
        case "derivated":
        case "registered":
        case "started":
          return "";
        default:
          return "disabled";
      }
    },

    getInstitutionName(appointment) {
      return appointment.institution?.name || "N/A";
    },

    goToPatientDashboard(id) {
      helperService.callIfNoSelectedText(() =>
        this.$router.push({
          name: "patient-dashboard",
          params: { id },
        })
      );
    },
  },

  watch: {
    selectedWorkplace() {
      this.$store.commit("setWorkplace", this.selectedWorkplace);
      this.getDerivableQueues();
    },
  },
};
</script>

<template lang="pug">
section
  header.headline
    .headline__title
      h1
        | Admisiones
        small(v-if="!isLoading && pagination.total")  ({{ pagination.total }})
      hr
    .headline__actions
      el-select(
        v-if="workplaces && workplaces.length"
        v-model="selectedWorkplace"
        placeholder="Puesto de trabajo"
      )
        el-option(
          v-for="workplace in workplaces"
          :key="workplace.id"
          :label="workplace.name"
          :value="workplace.id"
        )
  .box.box--with-subnav
    .box__content--stretch.container
      spinner(v-if="isLoading")
      h2.no-results(v-if="!isLoading && !appointments.length") No hay turnos
      template(v-if="!isLoading && appointments.length")
        .overlay(v-if="!selectedWorkplace && theme === 'ccr'")
          h2.no-results Debe seleccionar el puesto de trabajo
        table(v-else)
          thead
            tr
              th Inicio
              th Paciente
              th Institución
              th Doctor
              th Modalidad
              th Especialidad
              th Cobertura
              th Estado
              th Acciones
          tbody
            tr(v-for='appointment in appointments' :key="appointment._id" :class="getAppointmentClass(appointment)" @click="goToPatientDashboard(appointment.patient._id)")
              td {{ appointment.startsAt | formatDateAndTime }}
              td {{ getFullName(appointment.patient) }}
              td {{ getInstitutionName(appointment) }}
              td {{ getFullName(appointment.doctor) }}
              td {{ getAttentionTypeName(appointment.attentionType) }}
              td {{ appointment.medicalSpecialty.name }}
              td {{ getMedicalInsurance(appointment.patient)}}
              td {{ getAttentionStatus(appointment.status) }}
              td.actions
                .actions-container
                  el-dropdown(trigger="click")
                    .button.button--action.el-dropdown-link(@click.stop)
                      micon(name="more_horiz")
                    el-dropdown-menu(slot="dropdown")
                      //- el-dropdown-item
                      //-   div()
                      //-     | Admisionar paciente
                      el-dropdown-item(:disabled="appointment.status !== 'ready' || isCurrentAppointment(appointment._id)")
                        div(@click="callAppointment(appointment._id)")
                          | Llamar paciente
                      el-dropdown-item(:disabled="!isCurrentAppointment(appointment._id)")
                        div(@click="showModal(appointment._id)")
                          | Derivar paciente
                      el-dropdown-item(:disabled="!isCurrentAppointment(appointment._id)")
                        div(@click="endAppointment(appointment)")
                          | Finalizar turno

    pagination(
      :isLoading="isLoading"
      :limit="pagination.limit"
      :total="pagination.total"
      :derivableQueues="derivableQueues"
      @pagination="setPagination"
    )
              
    modal-derivation(
      v-if="isModalVisible"
      :show-dialog-modal="isModalVisible"
      :derivableQueues="derivableQueues"
      :selectedAppointmentId="selectedAppointmentId"
      :currentAppointment="currentAppointment"
      @appointmentDerivated="appointmentDerivated"
      @close="closeModal"
    )
</template>

<style lang="scss" scoped>
.container {
  position: relative;

  .overlay {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $light-gray;
    z-index: 10;
    pointer-events: none;
  }

  .active {
    background-color: $primary;
    color: white;

    &:hover {
      td {
        background-color: $primary;
      }
    }
  }

  .disabled {
    background-color: $light-gray;
    opacity: 0.5;

    &:hover {
      td {
        background-color: $light-gray;
      }
    }
  }

  .no-results {
    text-align: center;
    padding: 50px;
    font-size: 2em;
    width: 100%;
  }
}
</style>
