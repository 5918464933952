<script>
import { mapGetters } from "vuex";

import leave from "@/mixins/leave";
import medicalInsuranceService from "@/services/medical-insurance";
import Spinner from "@/components/ui/Spinner";
import InputTag from "@/components/ui/InputTag";

export default {
  name: "ModalMedicalInsurance",
  mixins: [leave],

  components: {
    Spinner,
    InputTag,
  },

  props: {
    showDialogModal: {
      type: Boolean,
    },
    currentMedicalInsurance: {
      type: Object,
    },
  },

  data() {
    return {
      isLoading: false,
      isPosting: false,
      medicalInsuranceId: null,
      medicalInsurance: {},
      isFetchingInsurance: false,
      isUpdate: !!this.currentMedicalInsurance?._id,
      isShowed: this.showDialogModal,
    };
  },

  computed: {
    ...mapGetters(["isAdmin", "isDoctor"]),
  },

  created() {
    if (this.isUpdate) {
      this.medicalInsurance = this.currentMedicalInsurance;
    }
  },

  mounted() {
    document.addEventListener("keyup", this.escape);
  },

  beforeDestroy() {
    document.removeEventListener("keyup", this.escape);
  },

  methods: {
    escape(event) {
      if (event.keyCode == 27) this.close();
    },

    submit() {
      if (this.isUpdate) {
        this.updateMedicalInsurance();
      } else {
        this.creteMedicalInsurance();
      }
    },

    creteMedicalInsurance() {
      this.isPosting = true;

      medicalInsuranceService
        .createMedicalInsurance(this.medicalInsurance)
        .then((medicalInsurance) => {
          this.medicalInsuranceId = medicalInsurance._id;
          this.$bus.$emit("medical-insurance-created", medicalInsurance);
          this.close();
        })
        .catch(() => {
          this.isPosting = false;
        });
    },

    updateMedicalInsurance() {
      this.isPosting = true;

      medicalInsuranceService
        .updateMedicalInsurance(this.medicalInsurance)
        .then((medicalInsurance) => {
          this.medicalInsuranceId = medicalInsurance._id;
          this.$bus.$emit("medical-insurance-updated", medicalInsurance);
          this.close();
        })
        .catch(() => {
          this.isPosting = false;
        });
    },

    close() {
      this.isPosting = false;
      this.isShowed = false;
      this.$emit("close");
    },
  },
};
</script>

<template lang="pug">
    ValidationObserver(v-slot="{handleSubmit}")
      form.modal(@submit.prevent="handleSubmit(submit)")
        header.modal__header
          h2.modal__title Prestador
          .modal__actions
            el-button(type="info" @click="close()") Cancelar
            el-button.border(type="primary" native-type="submit" :loading="isPosting") Guardar
        p.modal__subtitle Los campos con (*) son obligatorios
        .modal__content(v-if="isFetchingInsurance")
          spinner
        .modal__content
          .modal__block
            .modal__section
              .modal__sign.sign
                .sign__icon
                  micon(name="assignment")
                h3.sign__title Detalle
              article.modal__fields
                .modal__row
                  fieldset.modal__field
                    label.label * Nombre
                    ValidationProvider(name="Name" rules="required" v-slot="{ errors }")
                      el-input(v-model="medicalInsurance.name" placeholder="Ingrese nombre")
                      span.has-error {{errors[0]}}
                .modal__row     
                  fieldset.modal__field
                    label.label * Abreviación
                    ValidationProvider(name="Abbreviation" rules="required" v-slot="{ errors }")
                      el-input(v-model="medicalInsurance.abbreviation" placeholder="Ingrese abreviación")
                      span.has-error {{errors[0]}}
</template>

<style lang="scss" scoped>
.location {
  .micon {
    margin-right: 10px;
  }
}

.modal {
  z-index: 9999;
}

.event-detail-modal {
  h2 {
    font-weight: bold;
    margin-bottom: 20px;
  }

  h3 {
    margin: 0 0 20px;
  }

  .form-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    .description {
      word-break: break-word;
      font-weight: bold;
      color: $primary;
      margin-bottom: 10px;
    }

    .response {
      margin-left: 20px;
      display: list-item;
      list-style-type: "→ ";
    }
  }

  .view-task {
    padding: 1rem;
  }
}

@media (min-width: 446px) {
  .container {
    flex-direction: row !important;
    justify-content: space-between;
  }

  .betweenStreet {
    width: 48.5% !important;

    &:last-child {
      margin-top: 0 !important;
    }
  }
}
</style>
