<script>
import { mapGetters } from "vuex";
import ModalPatientDetail from "../patient/ModalPatientDetail";
import helperService from "@/services/helper";
import patientService from "@/services/patient";
import appointmentService from "@/services/appointment";
import {
  APPOINTMENTS_TYPES,
  VIDEOROOM_MM_OFFSET_PRE,
  VIDEOROOM_MM_OFFSET_POST,
} from "@/services/constants";
import { format, addMilliseconds } from "date-fns";
import pagination from "@/mixins/pagination";
import Spinner from "@/components/ui/Spinner";
import debounce from "debounce";
import { isVideoRoomEnabled } from "@/services/config";

const DEBOUNCE_MS_TIMEOUT = 500;

export default {
  name: "ModalAppointment",

  mixins: [pagination],

  components: {
    ModalPatientDetail,
    Spinner,
  },

  props: {
    showDialogModal: {
      type: Boolean,
      default: false,
    },
    selectedSlot: {
      type: Object,
    },
    selectedAppointment: {
      type: Object,
    },
    selectedMedicalSpecialty: {
      type: Object,
    },
  },

  data() {
    return {
      appointmentTypes: APPOINTMENTS_TYPES,
      attentionTypes: [],
      patients: [],
      searchInput: "",
      selectedPatient: null,
      appointment: {
        slot: this.selectedSlot._id,
        patient: null,
        medicalSpecialty: this.selectedMedicalSpecialty._id,
        institution: this.selectedSlot.institution,
        description: "",
        status: "pending",
        attentionType:
          this.selectedSlot.attentionType?.length > 1
            ? ""
            : this.selectedSlot.attentionType[0],
        videoRoom: null,
      },
      institution: null,
      isNewAppointment: !this.selectedSlot.appointments?.length,
      isPosting: false,
      isDeleting: false,
      isLoading: false,
      showDialogPatientModal: false,
    };
  },

  computed: {
    ...mapGetters(["isPatient", "user"]),

    isVideoRoomAvailable() {
      const now = new Date();

      const offsetStartsAt = new Date(this.appointment.startsAt);
      const offsetEndsAt = new Date(this.appointment.endsAt);

      offsetStartsAt.setMinutes(
        offsetStartsAt.getMinutes() - VIDEOROOM_MM_OFFSET_PRE
      );
      offsetEndsAt.setMinutes(
        offsetEndsAt.getMinutes() + VIDEOROOM_MM_OFFSET_POST
      );

      return (
        this.appointment.attentionType === "virtual" &&
        offsetStartsAt <= now &&
        offsetEndsAt >= now &&
        isVideoRoomEnabled
      );
    },

    canCancelAppointment() {
      if (this.isPatient) {
        return this.appointment.patient === this.user._id;
      } else {
        return true;
      }
    },
  },

  created() {
    if (!this.isNewAppointment) {
      this.getAppointment();
    }
    this.getPatients();
    this.setAttentionTypes();

    this.$bus.$on("patient-update", (patient) => {
      this.patients.unshift(patient);
    });
  },

  methods: {
    showModal() {
      this.showDialogPatientModal = true;
    },

    closeModal() {
      this.showDialogPatientModal = false;
    },

    async getPatients(query = {}) {
      this.isLoading = true;

      const defaultQuery = {
        offset: this.pagination.offset,
        limit: this.pagination.limit,
        ...query,
      };

      patientService
        .get(defaultQuery)
        .then((res) => {
          this.patients = res.docs;
          this.pagination.total = res.total;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    debounceInput: debounce(function () {
      const query = {
        offset: 0,
        limit: this.pagination.limit,
      };

      const input = this.searchInput && this.searchInput.split(" ");
      const useSearchIndex = input && (input.length === 1 || input.length > 3);

      // Search up to 3 words (Double names or double last names)
      const useNameIndex = input && input.length > 1 && input.length <= 3;

      if (useSearchIndex) {
        query.$text = {
          $search: this.searchInput,
        };
      } else if (useNameIndex) {
        Object.assign(query, helperService.createNameQuery(this.searchInput));
      }

      this.getPatients(query);
    }, DEBOUNCE_MS_TIMEOUT),

    async getAppointment() {
      this.appointment = await appointmentService.getAppointmentById(
        this.selectedAppointment._id
      );
      await this.getPatientById();
    },

    async getPatientById() {
      const patient = await patientService.getById(this.appointment.patient);
      this.selectedPatient = patient;
    },

    setAttentionTypes() {
      this.selectedSlot.attentionType.forEach((type) => {
        this.attentionTypes.push(APPOINTMENTS_TYPES[type]);
      });
    },

    selectPatient(patient) {
      this.selectedPatient = patient;
      this.appointment.patient = patient._id;
    },

    getFullName(user) {
      return helperService.getFullName(user);
    },

    getClassName(patient) {
      if (this.selectedPatient?._id === patient._id) {
        return "is-active";
      } else {
        return "";
      }
    },

    async createAppointment() {
      this.isPosting = true;
      try {
        const appointment = await appointmentService.createAppointment(
          this.appointment
        );
        this.$bus.$emit("appointment-created", this.selectedSlot, appointment);
      } catch (error) {
        console.log(error);
      }

      this.isPosting = false;
      this.close();
    },

    cancelAppointment() {
      this.$confirm(`¿Desea cancelar el turno?`, "Cancelar", {
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        type: "warning",
      })
        .then(() => {
          this.isDeleting = true;
          appointmentService
            .cancelAppointment(this.appointment._id)
            .then((deletedAppointment) => {
              this.$bus.$emit("appointment-deleted", deletedAppointment);
              this.isDeleting = false;
              this.close();
            });
        })
        .catch(() => {});
    },

    getStartAndEndHours(startsAt, endsAt) {
      const parsedStartHour = format(new Date(startsAt), "HH:mm");

      let parsedEndHour = format(addMilliseconds(new Date(endsAt), 1), "HH:mm");

      // workaround
      if (parsedEndHour === "00:00") {
        parsedEndHour = "24:00";
      }
      // workaround

      return `${parsedStartHour} - ${parsedEndHour}`;
    },

    async goToPatientVideoRoom() {
      this.$router.push({
        name: "patient-videoroom",
        params: {
          id: this.appointment.patient,
          ...(this.appointment && { appointmentId: this.appointment._id }),
        },
      });
    },

    getMedicalSpecialtiesNames(selectedSlot) {
      return (
        selectedSlot.medicalSpecialties?.map((m) => m?.name).join(", ") || "N/A"
      );
    },

    getMedicalInsuranceNumber(patient) {
      return patient?.patient?.medicalInsurance?.number || "N/A";
    },

    submit() {
      this.createAppointment();
    },

    close() {
      this.$emit("close");
    },

    addOvershift() {
      this.isNewAppointment = true;
      this.selectedPatient = null;
      this.appointment = {
        slot: this.selectedSlot._id,
        patient: null,
        description: "",
        status: "pending",
        attentionType:
          this.selectedSlot.attentionType?.length > 1
            ? ""
            : this.selectedSlot.attentionType[0],
        videoRoom: null,
        medicalSpecialty: this.selectedMedicalSpecialty._id,
        institution: this.selectedSlot.institution,
      };
    },
  },
};
</script>

<template lang="pug">
el-dialog(:visible.sync="showDialogModal", :before-close="close")
    ValidationObserver(v-slot="{handleSubmit}")
      form.modal(@submit.prevent="handleSubmit(submit)")
        header.modal__header(v-if='isNewAppointment')
          h2.modal__title Agendar nuevo turno
          .modal__actions
            el-button(type="info" @click="close()" :disabled='isPosting') Cancelar
            el-button.border(type="primary" native-type="submit" :loading="isPosting") Agendar
        header.modal__header(v-else)
          h2.modal__title Turno asignado
          .modal__actions
            el-button.border(v-if="selectedAppointment" type="primary" @click="addOvershift" :disabled='isPosting') Agregar sobreturno
            el-button.border(type="primary" @click="close()" :disabled='isPosting') Cerrar
        .modal__content
          .modal__block
            .modal__section
              .modal__sign.sign
                .sign__icon
                  micon(name="calendar")
                h3.sign__title Datos del turno
              article.modal__fields
                .modal__row
                  fieldset.modal__field
                    label.label Fecha
                    p {{ selectedSlot.startsAt | formatDate}}
                  fieldset.modal__field
                    label.label Horario
                    p {{ getStartAndEndHours(selectedSlot.startsAt, selectedSlot.endsAt)}}
                  fieldset.modal__field
                    el-button.room-button(v-if='isVideoRoomAvailable' type="info" :loading="isPosting" @click='goToPatientVideoRoom') Ingresar a la sala
                .modal__row    
                  fieldset.modal__field
                    label.label Doctor
                    p {{ getFullName(selectedSlot.doctor) }}
                  fieldset.modal__field
                    label.label Especialidad
                    p {{ isNewAppointment ? selectedMedicalSpecialty.name : appointment.medicalSpecialty.name}}
                  fieldset.modal__field
                    label.label Lugar 
                    p {{ selectedSlot.institution.name }} - {{ selectedSlot.institution.address.street }} {{selectedSlot.institution.address.buildingNumber}}, {{ selectedSlot.institution.address.district }} {{selectedSlot.institution.address.postalCode }}, {{ selectedSlot.institution.address.district }}, {{ selectedSlot.institution.address.city }} {{ selectedSlot.institution.address.apartmentFloor ? `, Piso ${selectedSlot.institution.address.apartmentFloor}` : "" }} {{ selectedSlot.institution.address.apartmentNumber ? `, Número ${selectedSlot.institution.address.apartmentNumber}` : "" }}  
                .modal__row
                  fieldset.modal__field(v-if='isNewAppointment')
                    label.label * Modalidad
                    ValidationProvider(name="Modalidad", rules="required", v-slot="{ errors }")
                      el-select(
                        v-model="appointment.attentionType",
                        placeholder='Seleccione el tipo de turno'
                        required
                        default-first-option
                      )
                        el-option(
                          v-for="type in attentionTypes",
                          :key="type.code",
                          :label="type.friendlyName",
                          :value="type.value"
                        )  
                      span.has-error {{ errors[0] }} 
                  fieldset.modal__field(v-else)
                    label.label Modalidad
                    p(v-if='appointment.attentionType') {{ appointmentTypes[appointment.attentionType].friendlyName }}  
                div
                  .modal__row
                    fieldset.modal__field
                      label.label * Paciente
                      ValidationProvider(name="Paciente", rules="required", v-slot="{ errors }")
                        .patient
                          el-input(
                            :value="getFullName(selectedPatient)"
                            placeholder="Seleccione un paciente"
                            readOnly
                          )
                          button.button.button--blue(
                            v-if="isNewAppointment"
                            v-auth="'patient.create'"
                            @click.prevent="showModal"
                          )
                            micon(name="add_circle_outline").button__icon
                            span.button__text Nuevo Paciente
                        span.has-error {{ errors[0] }} 

                  .box.box_table(v-if='isNewAppointment' :class='selectedPatient ? "selected" : ""')
                    el-input(
                      prefix-icon="el-icon-search"
                      v-on:input="debounceInput"
                      v-model="searchInput"
                      placeholder="Buscar"
                      :clearable="true"
                    )
                    .box__content--stretch
                      table
                        thead
                          tr
                            th Paciente
                            th Documento
                            th Número de Socio

                        tbody
                          spinner(v-if="isLoading")
                          tr(v-else v-for="(patient, index) in patients" :key="patient._id" :class="getClassName(patient)" @click="selectPatient(patient)")
                            td
                              b {{ getFullName(patient) }}
                            td {{ patient.governmentId && patient.governmentId.number }}
                            td td {{ getMedicalInsuranceNumber(patient) }}

                    pagination(
                      :isLoading="isLoading"
                      :limit="pagination.limit"
                      :total="pagination.total"
                      @pagination="setPagination"
                    )      

                  .modal__row.patientData(v-if='selectedPatient')
                    fieldset.modal__field
                      label.label Mail
                      p {{ selectedPatient.email }}
                    fieldset.modal__field
                      label.label Teléfono
                      p {{ selectedPatient.phone }}

                  .modal__row.cancel-button(v-if='!isNewAppointment && canCancelAppointment')
                    fieldset.modal__field
                      el-button(type="info" :loading="isPosting" @click='cancelAppointment') Cancelar turno

    modal-patient-detail(
      v-if="showDialogPatientModal"
      @close="closeModal",
    )
</template>

<style lang="scss" scoped>
.label {
  position: relative;
  .search-other {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.selected {
  height: auto;
}

.box_table {
  margin: 1rem 0.8rem 0;
}

.cancel-button {
  margin-top: 2rem;

  button {
    border: none;
    background-color: #c0392b;
  }

  button:hover {
    background-color: #892a1f;
  }
}

.room-button {
  border: none;
  background-color: rgb(113, 215, 135);
}

.patient {
  display: flex;
}

.is-active {
  background-color: $primary;
  color: $white;
}

.is-active:hover,
.is-active:hover td {
  background-color: $primary !important;
}

.patientData {
  margin-top: 1.5rem;
}

.notAvailable {
  text-align: center;
  margin-top: 1.5rem;
}
</style>
