import api from "./api";

const episodeService = {};
const getEndpoint = ({ episodeId, patientId }) => {
  if (episodeId) {
    return `/episodes/${episodeId}/patients/${patientId}`;
  }
  return `/episodes/patients/${patientId}`;
};

episodeService.createEpisodeTreatment = function ({
  patientId,
  treatmentId,
  episodeId,
  startsAt,
}) {
  const endpoint = getEndpoint({ episodeId, patientId });
  return api
    .post(`${endpoint}/treatments`, {
      treatment: treatmentId,
      startsAt,
    })
    .then((res) => res.data);
};

episodeService.removeEpisodeTreatment = function ({
  patientId,
  episodeId,
  episodeTreatmentId,
}) {
  const endpoint = getEndpoint({ episodeId, patientId });
  return api
    .delete(`${endpoint}/treatments/${episodeTreatmentId}`)
    .then((res) => res.data);
};

episodeService.create = function (patientId, data) {
  return api.post(getEndpoint({ patientId }), data).then((res) => res.data);
};

episodeService.get = function (patientId, query = {}) {
  return api
    .get(getEndpoint({ patientId }), { params: query })
    .then((res) => res.data);
};

episodeService.getActive = function (patientId, query = {}) {
  return api.get(getEndpoint({ patientId }), { params: query }).then((res) =>
    res.data.docs.find(
      ({ status }) => status === "active" // There should at least one active episode (For now only one)
    )
  );
};

episodeService.update = function ({ episodeId, patientId, body }) {
  return api
    .put(`${getEndpoint({ episodeId, patientId })}`, body)
    .then((res) => res.data);
};

export default episodeService;
