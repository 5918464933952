const helperService = {};
const patientRole = "000000000000000000000003";
export const capitalize = (str) =>
  str ? `${str[0].toUpperCase()}${str.slice(1)}` : "";

helperService.buildQuery = function (queryParams) {
  let query = {};

  queryParams.searchInput ? (query = queryParams) : query;

  queryParams.offset && (query.offset = queryParams.offset);
  queryParams.limit && (query.limit = queryParams.limit);
  queryParams.sort && (query.sort = queryParams.sort);

  if (queryParams.searchInput) {
    const input = query.searchInput && query.searchInput.split(" ");
    const useSearchIndex = input && (input.length === 1 || input.length > 3);

    // Search up to 3 words (Double names or double last names)
    const useNameIndex = input && input.length > 1 && input.length <= 3;

    if (useSearchIndex) {
      query.$text = {
        $search: query.searchInput,
      };
    } else if (useNameIndex) {
      Object.assign(query, this.createNameQuery(query.searchInput));
    }
  }

  if (queryParams.searchTask) {
    query.$or = [];
    queryParams.queryProperties.forEach((param) => {
      query.$or.push({
        [param]: { $regex: queryParams.searchTask, $options: "i" },
      });
    });
  }

  if (queryParams.searchInstitution) {
    query.$or = [];
    queryParams.queryProperties.forEach((param) => {
      query.$or.push({
        [param]: { $regex: queryParams.searchInstitution, $options: "i" },
      });
    });
  }

  if (queryParams.searchMedicalSpecialty) {
    query.$or = [];
    queryParams.queryProperties.forEach((param) => {
      query.$or.push({
        [param]: { $regex: queryParams.searchMedicalSpecialty, $options: "i" },
      });
    });
  }

  if (queryParams.searchMedicalInsurance) {
    query.$or = [];
    queryParams.queryProperties.forEach((param) => {
      query.$or.push({
        [param]: { $regex: queryParams.searchMedicalInsurance, $options: "i" },
      });
    });
  }

  if (queryParams.searchTreatment) {
    query.$or = [];
    if (queryParams.queryProperties) {
      query.$or.push({
        [queryParams.queryProperties]: {
          $regex: queryParams.searchTreatment,
          $options: "i",
        },
      });
    }
  }

  if (queryParams.searchCompany) {
    query.$or = [];
    if (queryParams.queryProperties) {
      query.$or.push({
        [queryParams.queryProperties]: {
          $regex: queryParams.searchCompany,
          $options: "i",
        },
      });
    }
  }

  if (queryParams.vitalMatch) {
    query.$and = [];
    query.$and.push({
      [queryParams.queryProperties[0]]: { $exists: true },
      [queryParams.queryProperties[1]]: {
        $regex: queryParams.vitalMatch,
        $options: "i",
      },
    });
  }

  if (queryParams.userRole) {
    query.$or = [];
    query.$or.push({
      [queryParams.queryProperties]: {
        $exists: true,
        $ne: patientRole,
      },
    });
  }

  return query;
};

helperService.getFullName = function (user) {
  if (!user) {
    return "";
  }
  return `${capitalize(user.lastName)}, ${capitalize(user.firstName)}`;
};

helperService.getPatientDoctorsNames = function (doctors) {
  if (!doctors) {
    return "";
  }

  const hasOneDoctor = doctors.length === 1;
  if (hasOneDoctor) {
    const [doctor] = doctors;
    return `${capitalize(doctor.lastName)}, ${capitalize(doctor.firstName)}`;
  }

  return `${doctors.length} Médicos`;
};

helperService.filterMongoProps = function (arr = []) {
  const props = ["_id", "icon", "createdAt", "updatedAt", "id"];

  arr.forEach((obj) => {
    Object.keys(obj)
      .filter((key) => props.includes(key))
      .forEach((key) => delete obj[key]);
  });

  return arr;
};

// Avoid calling a fn when the user is just selecting text.
// E.g when the user is just selecting text in a table and
// doesn't want to click in the row
helperService.callIfNoSelectedText = function (cb) {
  const hasSelectedText = !!window.getSelection().toString();

  if (hasSelectedText) {
    return;
  } else {
    cb();
  }
};

// Search index is not working nicely when looking for name and last names.
// We are trying a custom approach by searching for name and last name directly.
helperService.createNameQuery = function (searchInput) {
  const or = [];
  const potentialNames = getPotentialNames(searchInput);

  potentialNames.forEach(function ({ firstName, lastName }) {
    if (firstName && lastName) {
      or.push({
        firstName: { $regex: firstName, $options: "i" },
        lastName: { $regex: lastName, $options: "i" },
      });
    } else if (firstName) {
      or.push({ firstName: { $regex: firstName, $options: "i" } });
    } else if (lastName) {
      or.push({ lastName: { $regex: lastName, $options: "i" } });
    }
  });

  return {
    $or: or,
  };
};

function getPotentialNames(input) {
  const parts = input.split(" ");

  if (parts.length === 2) {
    return [
      // Maybe the first word is the first name.
      { firstName: parts[0], lastName: parts[1] },
      // Maybe the first word is the last name.
      { firstName: parts[1], lastName: parts[0] },

      { firstName: `${parts[0]} ${parts[1]}` },
      { lastName: `${parts[0]} ${parts[1]}` },
    ];
    // In case the user is looking for a patient with 2 first names or 2 last
    // names.
  } else if (parts.length === 3) {
    return [
      // Example: Carlos Perez Duo (Compound last name)
      { firstName: parts[0], lastName: `${parts[1]} ${parts[2]}` },
      // Example: Perez Duo Carlos (Compound last name)
      { firstName: parts[2], lastName: `${parts[0]} ${parts[1]}` },

      // Example: Carlos Martin Perez (First and second name)
      { firstName: `${parts[0]} ${parts[1]}`, lastName: parts[2] },
      // Example: Perez Carlos Martin (First and second name)
      { firstName: `${parts[1]} ${parts[2]}`, lastName: parts[0] },
    ];
  }
}

export default helperService;
