import api from "./api";

const attentionService = {};
const endpoint = "/attentions";

attentionService.getAttentions = function ({ query }) {
  return api
    .get(endpoint, {
      params: {
        ...query,
      },
    })
    .then(({ data }) => data);
};

attentionService.updateAttention = function ({ attentionId, body }) {
  return api.put(`${endpoint}/${attentionId}`, body).then(({ data }) => data);
};

export default attentionService;
