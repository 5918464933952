<script>
import labelService from "@/services/labels";
import Spinner from "@/components/ui/Spinner";
import ModalLabel from "@/components/settings/label/ModalLabel.vue";
import { mapGetters } from "vuex";

export default {
  name: "LabelList",

  components: {
    Spinner,
    ModalLabel,
  },

  data() {
    return {
      isLoading: false,
      searchFilter: "",
      labels: [],
      isLabelModalVisible: false,
      selectedLabel: {},
    };
  },

  created() {
    this.getLabels();

    this.$bus.$on("label-created", (label) => {
      this.labels.push(label);
    });
  },

  methods: {
    async getLabels() {
      try {
        this.isLoading = true;
        const labels = await labelService.getLabels();
        this.labels = labels;
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },

    closeLabelModal() {
      this.isLabelModalVisible = false;
    },

    showLabelModal(label) {
      if (label) {
        this.selectedLabel = label;
      }
      this.isLabelModalVisible = true;
    },

    async deleteLabel(label) {
      this.$confirm(`¿Desea eliminar la etiqueta: ${label.name}?`, "Eliminar", {
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        type: "warning",
      })
        .then(async () => {
          await labelService.delete(label._id);
          this.labels = this.labels.filter((l) => l._id !== label._id);
        })
        .catch(() => {});
    },
  },

  computed: {
    ...mapGetters(["isAdmin"]),

    filteredLabels() {
      return this.labels.filter((label) =>
        label.name.toLowerCase().includes(this.searchFilter.toLowerCase())
      );
    },
  },
};
</script>

<template lang="pug">
  section
    header.headline
      .headline__title
        h1
          | Etiquetas
          small(v-if="labels.length")  ({{ labels.length }})
        hr
      .headline__actions
        el-input(prefix-icon="el-icon-search" v-model="searchFilter" placeholder="Buscar" :clearable="true")
        .new_label.button.button--blue.box__icon(@click='showLabelModal()' v-if='isAdmin')
          micon(name="add_circle_outline").button__icon
          span.button__text Nueva Etiquieta
    .box.box--with-subnav
      .box__content--stretch
        table
          thead
            tr
                th Nombre
                th Color
                th Acciones
          tbody
            spinner(v-if="isLoading")
            tr.tr-container(v-else v-for="(label, index) in filteredLabels" :key="label._id" @click='showLabelModal(label)')
              td {{ label.name }}
              td.color-container
                .color(:style="{'background-color': `${label.color}`}")
              td.actions
                .actions-container(@click.stop)
                  button.button.button--action(@click="deleteLabel(label)" v-if='isAdmin')
                    micon(name="delete_forever")

      ModalLabel(
         v-if='isLabelModalVisible'
        :show-dialog-modal='isLabelModalVisible' 
        :current-label='selectedLabel'
        @close='closeLabelModal'
      )
              
</template>

<style scoped>
tbody td:hover {
  cursor: pointer;
}

.color-container {
  width: 11%;
}

.color {
  width: 100%;
  height: 2em;
}
</style>
