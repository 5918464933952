import Vue from "vue";
import Vuex from "vuex";
import localStore from "store";
import { USER_ROLES } from "@/services/constants";

const { ADMIN, MANAGER, DOCTOR, ASSISTANT } = USER_ROLES;

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: localStore.get("token") || window.accessToken || "",
    user: localStore.get("user") || window.user || {},
    workplace: localStore.get("workplace") || window.workplace || undefined,
  },

  getters: {
    user({ user }) {
      return user;
    },

    role({ user }) {
      return user.role;
    },

    isDoctor({ user }) {
      return user.role === DOCTOR.name || user.role === DOCTOR.alias;
    },

    isAdmin({ user }) {
      return user.role === ADMIN.name || user.role === ADMIN.alias;
    },

    isAssistant({ user }) {
      return user.role === ASSISTANT.name || user.role === ASSISTANT.alias;
    },

    isManager({ user }) {
      return user.role === MANAGER.name || user.role === MANAGER.alias;
    },

    workplace({ workplace }) {
      return workplace;
    },
  },

  mutations: {
    setToken(state, token) {
      state.token = token;
      localStore.set("token", token);
    },
    setUser(state, user) {
      state.user = user;
      localStore.set("user", user);
    },
    setWorkplace(state, workplace) {
      state.workplace = workplace;
      localStore.set("workplace", workplace);
    },
  },

  actions: {},
});
