<script>
import debounce from "debounce";
import Spinner from "@/components/ui/Spinner";
import pagination from "@/mixins/pagination";
import medicalInsuranceService from "@/services/medical-insurance";
import helperService from "@/services/helper";
import ModalMedicalInsurance from "@/components/settings/medicalInsurance/ModalMedicalInsurance";

const DEBOUNCE_MS_TIMEOUT = 500;

export default {
  name: "MedicalInsuranceList",

  mixins: [pagination],

  components: {
    Spinner,
    ModalMedicalInsurance,
  },

  data() {
    return {
      isLoading: false,
      searchInput: "",
      medicalInsurances: [],
      isMedicalInsuranceModalVisible: false,
      selectedMedicalInsurance: {},
    };
  },

  created() {
    this.getMedicalInsurances();

    this.$bus.$on("medical-insurance-created", (medicalInsurance) => {
      this.medicalInsurances.unshift(medicalInsurance);
    });
  },

  methods: {
    async getMedicalInsurances(query = {}) {
      this.isLoading = true;

      const defaultQuery = {
        offset: this.pagination.offset,
        limit: this.pagination.limit,
        ...query,
      };

      medicalInsuranceService
        .getMedicalInsurances(defaultQuery)
        .then((res) => {
          this.medicalInsurances = res;
          this.pagination.total = res.total;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    debounceInput: debounce(function () {
      this.getMedicalInsurances(
        helperService.buildQuery({
          searchMedicalInsurance: this.searchInput,
          queryProperties: ["name"],
        })
      );
    }, DEBOUNCE_MS_TIMEOUT),

    showMedicalInsuranceModal(medicalInsurance) {
      if (medicalInsurance) {
        this.selectedMedicalInsurance = medicalInsurance;
      }
      this.isMedicalInsuranceModalVisible = true;
    },

    closeMedicalInsuranceModal() {
      this.isMedicalInsuranceModalVisible = false;
      this.selectedMedicalInsurance = null;
    },

    deleteMedicalInsurance(medicalInsurance) {
      this.$confirm(
        `¿Desea eliminar el presador: ${medicalInsurance.name}?`,
        "Bloquear",
        {
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cancelar",
          type: "warning",
        }
      )
        .then(() => {
          medicalInsuranceService.deleteMedicalInsurance(medicalInsurance._id);

          this.medicalInsurances = this.medicalInsurances.filter(
            (m) => m._id !== medicalInsurance._id
          );
        })
        .catch(() => {});
    },

    onPageChange() {
      this.getMedicalInsurances();
    },
  },
};
</script>

<template lang="pug">
  section.institution-list
    header.headline
      .headline__title
        h1
          | Prestadores
          small(v-if="pagination.total")  ({{ pagination.total }})
        hr
      .headline__actions
        el-input(
          prefix-icon="el-icon-search"
          @input="debounceInput"
          v-model="searchInput"
          placeholder="Buscar"
          :clearable="true"
        )
        .new_medical-specialty.button.button--blue.box__icon(@click='showMedicalInsuranceModal()' v-auth="['doctor.create', 'manager.create', 'assistan.create', 'admin.create']")
          micon(name="add_circle_outline").button__icon
          span.button__text Nuevo prestador
    .box
      .box__content--stretch
        table
          thead
            tr
              th Nombre
              th Abreviación
              th Acciones
          tbody
            spinner(v-if="isLoading")
            tr(v-else v-for="(medicalInsurance, index) in medicalInsurances" :key="medicalInsurance._id" @click='showMedicalInsuranceModal(medicalInsurance)')
              td {{ medicalInsurance.name }}
              td {{ medicalInsurance.abbreviation }}
              td.actions
                .actions-container
                  el-dropdown(trigger="click")
                    .button.button--action.el-dropdown-link(@click.stop)
                      micon(name="more_horiz")
                    el-dropdown-menu(slot="dropdown")
                      el-dropdown-item
                          div(@click="showMedicalInsuranceModal(medicalInsurance)")
                            micon(name="edit")
                            | Editar prestador
                      //- el-dropdown-item
                      //-     div(@click="deleteMedicalInsurance(insurance, index)")
                      //-       micon(name="lock")
                      //-       | Eliminar prestador

      ModalMedicalInsurance(
        v-if='isMedicalInsuranceModalVisible'
        :current-medical-insurance='selectedMedicalInsurance'
        :show-dialog-modal='isMedicalInsuranceModalVisible' 
        @close='closeMedicalInsuranceModal'
      )

      pagination(
        :isLoading="isLoading"
        :limit="pagination.limit"
        :total="pagination.total"
        @pagination="setPagination"
      )

</template>

<style lang="scss" scoped>
@import "@/styles/settings/index.scss";
@import "@/styles/tools/index.scss";

tbody td:hover {
  cursor: pointer;
}

.box {
  height: 100%;
}

.institution-list {
  height: 65vh;
  .headline {
    .headline__title {
      @include media(desktop-up) {
        margin: 0 15px 15px 0;
      }
      @include media(web-up) {
        margin: 0 15px 0 0;
      }
    }
    .headline__actions {
      @include media(mobile-up) {
        width: 100%;
      }
      @include media(web-up) {
        width: auto;
      }
    }
  }
}
</style>
