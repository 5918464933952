<script>
/*
This whole file has a tricky workaround to handle UTC and GMT differences.
The main reason is that the calendar element ui is based in UTC, but when you get a value from it, it turns it into GMT.

For example: If you select a random date such as 15/07, the calendar element guess it's `2022-07-15T00:00:00.000Z` but when
you save the value in a prop it saves as `2022-07-14T21:00:00.000 GMT-0300`.

It's a reported bug, you can find the demos in their webpage bugging https://element.eleme.io/#/es/component/calendar
(Click on a date in Basic Calendar demo and see what happens)

I've marked the 'tricky' parts with a comment to identify faster those parts. Find the word 'workaround'.
*/
import Spinner from "@/components/ui/Spinner";
import slotService from "@/services/slot";
import moment from "moment";

export default {
  name: "slotsCalendar",

  props: {
    selectedDoctorId: {
      type: String,
      required: true,
    },
    selectedInstitutionId: {
      type: String,
      required: true,
    },
    selectedMedicalSpecialtyId: {
      type: String,
      required: true,
    },
  },

  components: {
    Spinner,
  },

  data() {
    return {
      selectedDate: "",
      selectedMonth: "",
      slots: [],
      isloadingSlots: false,
    };
  },

  created() {
    const now = moment();

    // workaround
    const offset = moment().utcOffset() * -1;
    this.selectedDate = now.subtract(offset, "minutes").toDate();
    // workaround

    this.selectedMonth = now.utc().month() + 1;
  },

  watch: {
    selectedDate() {
      // workaround
      this.selectedMonth = moment(this.selectedDate).utc().month() + 1;
      // workaround

      if (this.selectedDate) {
        this.$bus.$emit("selected-date", this.selectedDate);
      }
    },

    selectedMonth() {
      this.getSlotsAvailability();
    },

    selectedDoctorId() {
      this.slots = [];
      this.getSlotsAvailability();
    },

    selectedMedicalSpecialtyId() {
      this.slots = [];
      this.getSlotsAvailability();
    },

    selectedInstitutionId() {
      this.slots = [];
      this.getSlotsAvailability();
    },
  },

  methods: {
    async getSlotsAvailability() {
      if (
        this.selectedInstitutionId &&
        this.selectedDoctorId &&
        this.selectedMedicalSpecialtyId
      ) {
        this.isloadingSlots = true;
        this.slots = [];

        try {
          const now = moment().format();
          const startDate = this.getDateFirstDayOfMonth(this.selectedDate);
          const endDate = this.getDateLastDayOfMonth(this.selectedDate);

          const query = {
            doctor: this.selectedDoctorId,
            medicalSpecialties: this.selectedMedicalSpecialtyId,
            institution: this.selectedInstitutionId,
            startDate,
          };

          if (endDate > now) {
            query.endDate = endDate;
          } else {
            return;
          }

          if (startDate < now) {
            query.startDate = now;
          } else {
            query.startDate = startDate;
          }

          const fetchedSlots = await slotService.getSlotsAvailability(query);

          const groupedSlots = this.groupSlotsByDate(fetchedSlots);

          this.slots = groupedSlots;
        } finally {
          this.isloadingSlots = false;
        }
      }
    },

    groupSlotsByDate(slots) {
      const groupedSlots = [];

      slots.reduce((acc, currentElem) => {
        const date = moment(currentElem.startsAt).format("YYYY-MM-DD");

        if (!acc[date]) {
          acc[date] = { date, availableSlots: 0 };
          groupedSlots.push(acc[date]);
        }

        acc[date].availableSlots += currentElem.available;
        return acc;
      }, {});

      return groupedSlots;
    },

    getDateFirstDayOfMonth(date) {
      // workaround
      const firstDay = moment(date)
        .startOf("month")
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSSZ");

      return firstDay;
      // workaround
    },

    getDateLastDayOfMonth(date) {
      // workaround
      const lastDay = moment(date)
        .endOf("month")
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSSZ");

      return lastDay;
      // workaround
    },

    getDay(date) {
      const day = date.split("-");
      return day[2];
    },

    isDayAvailable(day) {
      if (typeof day === "object") {
        day = day.toISOString().split("T")[0];
      } else {
        day = day.split("T")[0];
      }

      const [daySlots] = this.slots.filter((slot) => {
        return day === slot.date;
      });

      if (!daySlots) return "without-agenda";

      return daySlots.availableSlots >= 1 ? "available" : "not-available";
    },

    setSelectedDate(date) {
      const offset = moment().utcOffset() * -1;

      const correctedDate = moment(date)
        .add(offset, "minutes")
        .format("YYYY-MM-DDTHH:mm:ss.SSSZ");

      this.selectedDate = correctedDate;
    },
  },
};
</script>

<template lang="pug">
.calendar
  spinner(v-if="isloadingSlots")
  el-calendar(:first-day-of-week="7" @input="setSelectedDate" format="yyyy/MM/dd" disabled=true)
    template(slot="dateCell" slot-scope="{date, data}")
      div(:class="[isDayAvailable(data.day), data.isSelected ? 'is-selected' : '', 'slot-cell']")
        span {{ getDay(data.day) }}
</template>

<style lang="scss" scoped>
.calendar {
  position: relative;
}

.slot-cell {
  padding: 8px;
  width: 100%;
  height: 100%;
}

.without-agenda {
  cursor: default;
}

.available {
  background-color: lightgreen;
}

.not-available {
  background-color: lightcoral;
}

.is-selected {
  color: white;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
}
</style>
