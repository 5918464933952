import api from "./api";
import store from "@/store";

const debMediaService = {};
const endpoint = "/debmedia";

debMediaService.getWorkplaces = function () {
  return api.get(`${endpoint}/appointments/workplaces`).then((res) => res.data);
};

debMediaService.callAppointment = function ({ appointmentId }) {
  return api
    .post(`${endpoint}/appointments/${appointmentId}/call`, {
      headers: { worker: store.getters.workplace },
    })
    .then((res) => res.data);
};

debMediaService.getDerivableQueues = function () {
  return api
    .get(`${endpoint}/appointments/queues`, {
      headers: { worker: store.getters.workplace },
    })
    .then((res) => res.data);
};

debMediaService.derivateAppointment = function ({ appointmentId, queueId }) {
  return api
    .post(`${endpoint}/appointments/${appointmentId}/derivate/${queueId}`, {
      headers: { worker: store.getters.workplace },
    })
    .then((res) => res.data);
};

debMediaService.getCurrentAppointment = function () {
  return api
    .get(`${endpoint}/appointments/current`, {
      headers: { worker: store.getters.workplace },
    })
    .then((res) => res.data);
};

export default debMediaService;
