import { version } from "../../package.json";
window.APP_VERSION = version;

export const theme = process.env.VUE_APP_THEME || "default";

export const apiUrl = process.env.VUE_APP_API_URL || "http://localhost:3000";

export const s3Url =
  process.env.VUE_APP_S3_URL || "https://gizconnection-local.s3.amazonaws.com";

export const audience = process.env.VUE_APP_AUDIENCE || "dashboard";

export const marketingWebsiteUrl =
  process.env.VUE_APP_MARKETING_WEBSITE_URL ||
  "https://gizconnection.com/patient.html";

export const iOSAppStoreUrl =
  process.env.VUE_APP_IOS_APP_STORE_URL || "javascript:;";

export const androidAppStoreUrl =
  process.env.VUE_APP_ANDROID_APP_STORE_URL || "javascript:;";

export const loginRedirectURL =
  process.env.VUE_APP_SCORES_REDIRECT_URL ||
  "https://dashboard.gizconnection.com/login";

export const supportEmail =
  process.env.VUE_APP_SUPPORT_EMAIL || "soporte@gizconnection.com";

export const mobileAppName =
  process.env.VUE_APP_MOBILE_APP_NAME || "GizConnection";

export const organizationId = process.env.VUE_APP_ORGANIZATION_ID || null;

export const enableChat =
  process.env.VUE_APP_ENABLE_CHAT === "true" ? true : false;

export const enableScores =
  process.env.VUE_APP_ENABLE_SCORES === "true" ? true : false;

export const enableEpisodeCreation =
  process.env.VUE_APP_ENABLE_EPISODE_CREATION === "false" ? false : true;

export const isIndicationsEnabled =
  process.env.VUE_APP_INDICATIONS_ENABLED === "false" ? false : true;

export const isAppointmentsEnabled =
  process.env.VUE_APP_APPOINTMENTS_ENABLED === "true" ? true : false;

export const isVideoRoomEnabled =
  process.env.VUE_APP_VIDEOROOM_ENABLED === "true" ? true : false;

export const isFormPrintingEnabled =
  process.env.VUE_APP_ENABLE_EVENTS_PRINT === "true" ? true : false;

export const isMassiveEnabled =
  process.env.VUE_APP_ENABLE_MASSIVE_ACTIONS === "true" ? true : false;

export default {
  theme,
  apiUrl,
  s3Url,
  audience,
  marketingWebsiteUrl,
  iOSAppStoreUrl,
  androidAppStoreUrl,
  supportEmail,
  mobileAppName,
  organizationId,
  loginRedirectURL,
};
