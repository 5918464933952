<script>
import pagination from "@/mixins/pagination";

import eventsService from "@/services/events";
import helperService from "@/services/helper";

import Spinner from "@/components/ui/Spinner";

export default {
  name: "DashboardPatientsEvaluation",

  mixins: [pagination],

  components: {
    Spinner,
  },

  data() {
    return {
      isLoading: false,
      isCompleteModalVisible: false,
      selectedEvent: {},
      searchInput: "",
      events: [],
    };
  },

  computed: {
    filteredEvents() {
      if (!this.searchInput) {
        return this.events;
      } else {
        return this.events.filter(({ patient }) => {
          return this.matchesName(patient, this.searchInput);
        });
      }
    },
  },

  watch: {
    "$route.name": {
      handler: function () {
        this.getData();
      },
      immediate: true,
    },
  },

  methods: {
    matchesName(user, query) {
      const _query = query.toLocaleLowerCase();
      return (
        user?.firstName.toLocaleLowerCase().includes(_query) ||
        user?.lastName.toLocaleLowerCase().includes(_query)
      );
    },

    getData(query = {}) {
      this.isLoading = true;

      const defaultQuery = {
        offset: this.pagination.offset,
        ...query,
      };

      eventsService
        .getRequiresNewServiceEvents(defaultQuery)
        .then((res) => {
          this.events = res.docs;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getDoctorFullName(event) {
      const doctors = event.patient.doctors;
      if (!doctors || !doctors.length) {
        return "Sin médico";
      }

      const [doctor] = doctors;
      return `${doctor.lastName}, ${doctor.firstName}`;
    },

    showCompleteModal(event) {
      this.isCompleteModalVisible = true;
      this.selectedEvent = {
        ...event,
        metadata: {
          ...event.metadata,
        },
      };
    },

    hideCompleteModal() {
      this.isCompleteModalVisible = false;
      this.selectedEvent = {};
    },

    updateEvent() {
      const selectedID = this.selectedEvent._id;

      const index = this.events.findIndex((e) => e._id === selectedID);
      this.events.splice(index, 1);

      const updatedEvent = {
        _id: this.selectedEvent._id,
        metadata: this.selectedEvent.metadata,
        completedAt: new Date(),
      };

      eventsService.update(updatedEvent).then(() => this.getData());
      this.isCompleteModalVisible = false;
      this.selectedEvent = {};
    },

    goToPatientDashboard(id) {
      helperService.callIfNoSelectedText(() =>
        this.$router.push({
          name: "patient-dashboard",
          params: { id },
        })
      );
    },
  },
};
</script>

<template lang="pug">
  section.patients-requires-new-service
    header.headline
      .headline__title
        h1
          | Requieren nuevo servicio
          small(v-if="pagination.total") ({{ pagination.total }})
        hr
      .headline__actions
        el-input(
          prefix-icon="el-icon-search",
          v-model="searchInput",
          placeholder="Buscar",
          :clearable="true"
        )
    .box.box--with-subnav
      .box__content--stretch
        spinner(v-if="isLoading && !events.length")

        h2.no-alerts(v-if="!isLoading && !events.length") No hay resultados
        table(v-if="events.length")
          thead
            tr
              th Paciente
              th Médico
              th Fecha
              th Acciones
          tbody
            tr(
              v-for="(event, index) in filteredEvents",
              :key="event.id",
              @click="goToPatientDashboard(event.patient._id)"
            )
              td {{ event.patient.lastName | capitalize }}, {{ event.patient.firstName | capitalize }}
              td {{ getDoctorFullName(event) }}
              td Hace {{ event.updatedAt | formatDistanceStrict }}
              td.actions
                .actions-container
                  button.button.button--action(
                    @click.stop="showCompleteModal(event)",
                    v-tooltip="'Completar'"
                  )
                    micon(name="check")

    el-dialog(
      :visible.sync="isCompleteModalVisible",
      custom-class="complete-event-modal",
      :before-close="hideCompleteModal"
    )
      span(slot="title")
        h2 Marcar evento como completado

      el-input(
        v-if="selectedEvent.metadata",
        v-model="selectedEvent.metadata.completedReason",
        placeholder="Descripción (opcional)",
        type="textarea"
      )

      span(slot="footer")
        button.button.button--blue(@click="updateEvent()") Aceptar
</template>

<style lang="scss">
.patients-requires-new-service {
  .box--with-subnav {
    min-height: 100px;
  }

  .no-alerts {
    text-align: center;
    padding: 50px;
    font-size: 2em;
    width: 100%;
  }

  tbody td:hover {
    cursor: pointer;
  }
}

.complete-event-modal {
  .el-dialog__body {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
</style>
