<script>
import moment from "moment";
import massiveActionsService from "@/services/massive-actions";
import companyService from "@/services/company";
import treatmentService from "@/services/treatment";
import MassiveActionsHistory from "./MassiveActionsHistory.vue";

export default {
  name: "MassiveTreatmentAssignation",

  data() {
    return {
      isLoading: false,
      isCreating: false,

      patients: [],
      patientsCount: null,

      company: undefined,
      companies: [],
      treatment: undefined,
      treatments: [],
      treatmentStartsAt: undefined,
      createdAt: undefined,

      assignTreatment: undefined,
      startsAt: undefined,
    };
  },

  components: {
    MassiveActionsHistory,
  },

  created() {
    this.getCompanies();
    this.getTreatments();
    this.getPatientsCount();
  },

  methods: {
    async getPatientsCount() {
      this.isLoading = true;
      this.patients = [];
      const data = await massiveActionsService.getPatientsCount({
        ...(this.createdAt && { createdAt: this.createdAt }),
        ...(this.company && { company: this.company }),
        ...(this.treatment && {
          treatment: this.treatment,
        }),
        ...(this.treatmentStartsAt && {
          treatmentStartsAt: this.treatmentStartsAt,
        }),
      });
      this.patientsCount = data.length;
      this.patients = data;
      this.isLoading = false;
    },

    async createMassiveProtocols() {
      const treatment = this.treatments.find(
        (elem) => elem.id === this.assignTreatment
      );

      const message = `¿Desea asignar el protocolo ${
        treatment.name
      } a partir del ${moment(this.startsAt).format("DD/MM/YYYY")} a los ${
        this.patientsCount
      } pacientes?`;

      this.$confirm(message, "Confirmar", {
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        dangerouslyUseHTMLString: true,
        type: "warning",
      }).then(async () => {
        this.isCreating = true;
        await massiveActionsService.createMassiveProtocols({
          patients: this.patients,
          assignationTreatment: this.assignTreatment,
          assignationDate: this.startsAt,
          type: "treatment-assignation",
          //filters
          ...(this.createdAt && { userCreationDate: this.createdAt }),
          ...(this.company && { company: this.company }),
          ...(this.treatment && {
            assignatedTreatment: this.treatment,
          }),
          ...(this.treatmentStartsAt && {
            assignatedTreatmentDate: this.treatmentStartsAt,
          }),
        });
        this.$bus.$emit("go-to-history");
        this.isCreating = false;
      });
    },

    getCompanies() {
      companyService.get().then(({ docs }) => (this.companies = docs));
    },

    getTreatments() {
      treatmentService.get().then(({ docs }) => {
        this.treatments = docs;
      });
    },

    isTreatmentDisabled(treatment) {
      if (this.treatment) {
        return this.treatment === treatment._id;
      } else {
        return false;
      }
    },

    submit() {
      this.createMassiveProtocols();
    },
  },

  watch: {
    createdAt() {
      this.getPatientsCount();
    },

    company() {
      this.getPatientsCount();
    },

    treatment() {
      this.treatmentStartsAt = undefined;
      this.getPatientsCount();
      this.assignTreatment = undefined;
      this.startsAt = undefined;
    },

    treatmentStartsAt() {
      this.getPatientsCount();
    },
  },
};
</script>

<template lang="pug">
ValidationObserver(v-slot="{handleSubmit}")
  form(@submit.prevent="handleSubmit(submit)")
    section.main
        header.headline
          .headline__title
            h1
              | Asignación masiva de protocolos
            hr

          .headline__actions
            el-button(
              type="primary",
              native-type="submit",
              :disabled="!patients.length || !assignTreatment || !startsAt"
              :loading='isCreating'
            ) Asignar


        .massive-protocols
          .filter-title
            h2 Filtros

          .filter-fields
            .filter
              h4 Empresa
              el-select(
                v-model='company'
                placeholder="Seleccione una empresa"
                filterable
                clearable
                default-first-option
                :disabled='isLoading'
              )
                el-option(
                  v-for='company in companies'
                  :key='company._id'
                  :label='company.name'
                  :value='company._id'
                )

            .filter
              h4 Fecha de creación de paciente
              ValidationProvider(name="fecha de creación" rules="priorToday" v-slot="{errors}")
                el-date-picker(
                  :disabled='isLoading'
                  v-model='createdAt'
                )
                span.has-error {{errors[0]}}

            .filter
              h4 Protocolo
              el-select(
                v-model="treatment"
                placeholder="Seleccione una opción"
                filterable
                clearable
                default-first-option
                :disabled='isLoading'
              )
                el-option(v-for="treatment in treatments" :label="treatment.name" :value="treatment._id" :key="treatment._id")
                el-option(label="Sin protocolo" value="none" key="none")
                el-option(label="Indistinto" :value="undefined" key="undefined")

            .filter
              h4   Fecha asignación de protocolo
              ValidationProvider(name="fecha de asignación" rules="priorToday" v-slot="{errors}")
                el-date-picker(
                  v-model='treatmentStartsAt'
                  :disabled='!treatment || treatment === "none" || isLoading'
                )
                span.has-error {{errors[0]}}

          .results-title
            h2 Resultados

          .results
            h2(v-if='isLoading') Cargando...
            h2(v-else) {{patientsCount || '0'}} pacientes

          .treatment-title
            h2 Asignar

          .treatment
            h2 Protocolo:
            ValidationProvider(name="Protocolo", rules="required", v-slot="{ errors }")
              el-select(
                v-model='assignTreatment'
                :disabled="!patients.length"
                placeholder="Seleccione un protocolo"
                filterable
                clearable
                default-first-option
              )
                el-option(v-for="treatment in treatments" :label="treatment.name" :value="treatment._id" :key="treatment._id" :disabled='isTreatmentDisabled(treatment)')
              span.has-error {{ errors[0] }}

            h2 Seleccione una fecha:
            ValidationProvider(name="Fecha", rules="required|includeToday", v-slot="{ errors }")
              el-date-picker(
                :disabled="!patients.length"
                v-model='startsAt'
              )
              span.has-error {{ errors[0] }}

</template>

<style lang="scss">
.main {
  .massive-protocols {
    display: grid;
    grid-template-columns: 20% 80%;
    margin-bottom: 50px;
    margin-top: 50px;
    align-items: center;
    grid-row-gap: 75px;

    .filter-title {
      justify-self: center;
    }
    .filter-fields {
      display: grid;
      grid-template-columns: auto auto;
      grid-gap: 10px;
    }
    .results-title {
      justify-self: center;
    }
    .treatment-title {
      justify-self: center;
    }
  }
}
</style>
