<script>
export default {
  name: "AttentionTag",

  props: {
    type: {
      type: String,
    },
    label: {
      type: String,
    },
  },

  data() {
    return {
      count: this.attentions?.length,
    };
  },
};
</script>

<template lang="pug">
  .tag-with-count(:class="`${type}-tag`")
    el-tag(type="warning") {{ label }}
</template>

<style lang="scss" scoped>
.tag-with-count {
  position: relative;

  .count {
    position: absolute;
    top: -7px;
    right: 5px;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    font-size: 11px;
  }

  .el-tag {
    margin-right: 10px;
    padding: 0 5px;
  }

  &.urgent-tag {
    .el-tag,
    .count {
      background-color: #fef0f0;
      border: 1px solid #fde2e2;
      color: #f56c6c;
    }
  }

  &.high-tag {
    .el-tag,
    .count {
      background-color: #fff5e5;
      border: 1px solid #faecd8;
      color: $orange;
      color: #e6723c;
    }
  }

  &.medium-tag {
    .el-tag,
    .count {
      background-color: #fdf6ec;
      border: 1px solid $corn;
      color: #e6a23c;
    }
  }

  &.low-tag {
    .el-tag,
    .count {
      background-color: #f0f9eb;
      border: 1px solid #e1f3d8;
      color: #67c23a;
    }
  }
}
</style>
