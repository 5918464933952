<script>
import MassiveTreatmentAssignation from "./MassiveTreatmentAssignation.vue";
import MassiveRecommendationsCreation from "./MassiveRecommendationsCreation.vue";
import MassivePatientsCreation from "./MassivePatientsCreation.vue";
import MassiveActionsHistory from "./MassiveActionsHistory.vue";

export default {
  name: "MassiveSettingsDashboard",

  components: {
    MassiveTreatmentAssignation,
    MassiveRecommendationsCreation,
    MassivePatientsCreation,
    MassiveActionsHistory,
  },

  data() {
    return {
      category: "treatments",
    };
  },

  created() {
    this.$bus.$on("go-to-history", () => this.setCategory("history"));
  },

  methods: {
    getClassName(type) {
      const className = `${type}-color`;

      if (this.category === type) {
        return `${className} is-active`;
      } else {
        return className;
      }
    },

    setCategory(category) {
      this.category = category;
    },
  },
};
</script>

<template lang="pug">
  section.sections
    article.row
      nav.sidebar
        a.sidebar__link(@click="setCategory('treatments')", :class="getClassName('treatments')")
          micon(name="assignment")
          strong.sidebar__text Asignación masiva de protocolos
        a.sidebar__link(@click="setCategory('recommendations')", :class="getClassName('recommendations')")
          micon(name="healing")
          strong.sidebar__text Alta masiva de recomendaciones
        a.sidebar__link(@click="setCategory('users')", :class="getClassName('users')")
          micon(name="people")
          strong.sidebar__text Alta masiva de pacientes
        a.sidebar__link(@click="setCategory('history')", :class="getClassName('history')")
          micon(name="history")
          strong.sidebar__text Historial
          
      .box.box--full.box--with-subnav
        .content
          .data
            MassiveTreatmentAssignation(v-if="category==='treatments'")
            MassiveRecommendationsCreation(v-if="category==='recommendations'")
            MassivePatientsCreation(v-if="category==='users'")
            MassiveActionsHistory(v-if="category==='history'")
</template>

<style lang="scss">
.sections {
  .title {
    margin: auto 5px;
  }

  .sidebar {
    box-shadow: -5px 0px 5px 0 rgba(0, 0, 0, 0.1);
    padding-right: 0;
    z-index: 999;
    .sidebar__link {
      &.is-active {
        /* background-color: $primary; */
        background-color: white;
        outline: 0 5px 0 0 solid white;

        .sidebar__text {
          color: $primary;
        }

        svg {
          fill: $primary;
        }
      }
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    padding: 50px;

    .data {
      width: 100%;
    }
  }

  .recommendations-color {
    .micon {
      fill: $recommendation;
    }
  }

  .treatments-color {
    .micon {
      fill: $recommendation;
    }
  }

  .users-color {
    .micon {
      fill: $recommendation;
    }
  }

  .box.box--full.box--with-subnav {
    min-height: 82vh;
  }
}
</style>
