<script>
import { ATTENTION_RISKS } from "@/services/constants";
import userService from "@/services/user";
import attentionService from "@/services/attention";
import patientService from "@/services/patient";
import AttentionTag from "@/components/dashboard/AttentionTag";

export default {
  name: "ModalAttention",

  components: {
    AttentionTag,
  },

  props: {
    attention: {
      type: Object,
    },
  },

  data() {
    return {
      canAssign: !this.attention.doctor,
      attentionForm: [],
      isLoading: false,
      selectedDoctor: undefined,
      doctors: [],
    };
  },

  created() {
    this.parseAttentionForm();
    this.getDoctors();
  },

  mounted() {
    document.addEventListener("keyup", this.escape);
  },

  beforeDestroy() {
    document.removeEventListener("keyup", this.escape);
  },

  methods: {
    async getDoctors() {
      this.isLoading = true;
      const { docs } = await userService.getDoctors({
        populate: "doctor.medicalSpecialties",
      });
      this.doctors = docs;
      this.isLoading = false;
    },

    getMedicalSpecialtyLabel(doctor) {
      if (!doctor.doctor?.medicalSpecialties?.length) {
        return "";
      }
      return `- ${doctor.doctor.medicalSpecialties[0].name}`;
    },

    async onSave() {
      this.isLoading = true;

      if (!this.attention.patient?.doctors?.includes(this.selectedDoctor)) {
        const updatedDoctors = [...this.attention.patient.doctors];
        updatedDoctors.push(this.selectedDoctor);

        await patientService.update({
          ...this.attention.patient,
          doctors: updatedDoctors,
        });
      }

      const updatedAttention = await attentionService.updateAttention({
        attentionId: this.attention._id,
        body: { ...this.attention, doctor: this.selectedDoctor },
      });

      this.$emit("attention-assigned", updatedAttention);
      this.isLoading = false;
      this.close();
    },

    getAttentionLabel(attention) {
      const { label } = ATTENTION_RISKS.find(
        (elem) => elem.value === attention.risk
      );

      return label;
    },

    escape(event) {
      if (event.keyCode == 27) this.close();
    },

    getAttentionRisk(attention) {
      const { label } = ATTENTION_RISKS.find(
        (elem) => elem.value === attention.risk
      );

      return label;
    },

    parseAttentionForm() {
      const parsedAttentionForm = [];

      this.attention.responses.forEach((item) => {
        const existingSection = parsedAttentionForm.find(
          (response) => response.section === item.section
        );

        if (existingSection) {
          existingSection.options.push({
            option: item.option,
            type: item.type,
            value: item.value,
          });
        } else {
          parsedAttentionForm.push({
            section: item.section,
            options: [
              {
                option: item.option,
                type: item.type,
                value: item.value,
              },
            ],
          });

          this.attentionForm = parsedAttentionForm;
        }
      });
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>

<template lang="pug">
  .modal
    header.modal__header
      h2.modal__title Solicitud de Atención
      .modal__actions
        el-button(type="info" @click="close()") Cerrar
        el-button(v-if="canAssign" type="danger" :loading="isLoading" :disabled="!selectedDoctor" @click="onSave()") Asignar
    .modal__content
      .modal__block
        .modal__section
          .modal__sign.sign
            .sign__icon
              micon(name="assignment")
            h3.sign__title Detalles de la Solicitud
          article.modal__fields
            .modal__row
              fieldset.modal__field
                h2 Estado
                p {{ attention.completedAt ? 'Completada' : 'Pendiente' }}
              fieldset.modal__field
                h2 Riesgo
                attention-tag(:type="attention.risk" :label="getAttentionLabel(attention)")
            .modal__row
              fieldset.modal__field
                h2 Especialidad
                p {{ attention.medicalSpecialty.name }}
              fieldset.modal__field(v-if="!attention.doctor")
                h2 Asignar profesional
                el-select#doctor-assigned(
                  v-model="selectedDoctor"
                  placeholder=""
                  filterable
                  clearable
                  default-first-option
                )
                  el-option(
                    v-for="doctor in doctors"
                    :key="doctor._id"
                    :label="`${doctor.lastName}, ${doctor.firstName} ${getMedicalSpecialtyLabel(doctor)}`"
                    :value="doctor._id"
                  )
            .modal__row(v-if="attention.score")
              fieldset.modal__field
                h2 Puntaje
                p {{ attention.score }}
            .modal__row(v-if="attention.reason.length")
              fieldset.modal__field
                h2 Motivo de la atención
                p(v-for="reason in attention.reason") {{ reason }}
            .modal__row(v-if="attentionForm.length")
              fieldset.modal__field
                h2 Formulario
            .modal__row
              fieldset.modal__field(v-for="section in attentionForm")
                label.label {{section.section}}
                p(v-for="option in section.options") {{ option.option }}  
</template>

<style lang="scss" scoped></style>
