<script>
import Spinner from "@/components/ui/Spinner";
import massiveActionsService from "@/services/massive-actions";
import MassiveActionsHistoryModal from "./MassiveActionsHistoryModal.vue";
import helperService from "@/services/helper";
import {
  massiveActionsTypes,
  massiveActionsStatuses,
} from "@/services/constants";

export default {
  name: "MassiveActionsHistory",

  data() {
    return {
      isLoading: false,
      isModalVisible: false,
      selectedMassiveAction: undefined,
      massiveActions: [],
      filteredMassiveActions: [],
      selectedType: undefined,
      massiveActionsTypes,
      massiveActionsStatuses,
    };
  },

  components: {
    MassiveActionsHistoryModal,
    Spinner,
  },

  created() {
    this.queryMassiveActions();
  },

  methods: {
    async queryMassiveActions() {
      this.isLoading = true;
      const massiveActions = await massiveActionsService.getMassiveActions();

      this.massiveActions = massiveActions;
      this.filteredMassiveActions = massiveActions;
      this.selectedType = undefined;
      this.isLoading = false;
    },

    showModal(massiveAction) {
      this.isModalVisible = true;
      this.selectedMassiveAction = massiveAction;
    },

    closeModal() {
      this.selectedMassiveAction = undefined;
      this.isModalVisible = false;
    },

    getLabel(type) {
      const massiveActionType = this.massiveActionsTypes.find(
        (elem) => elem.value === type
      );
      return massiveActionType.label;
    },

    getStatus(status) {
      const massiveActionStatus = this.massiveActionsStatuses.find(
        (elem) => elem.value === status
      );
      return massiveActionStatus.label;
    },

    getFullName(user) {
      return helperService.getFullName(user);
    },
  },

  watch: {
    selectedType() {
      if (!this.selectedType) {
        this.filteredMassiveActions = this.massiveActions;
      } else {
        const filteredMassiveActions = this.massiveActions.filter(
          (elem) => elem.type === this.selectedType
        );

        this.filteredMassiveActions = filteredMassiveActions;
      }
    },
  },
};
</script>

<template lang="pug">
  section
    header.headline
      .headline__title
        h1
          | Historial
        hr
      .headline__actions
        button(@click='queryMassiveActions()')
          micon(name="refresh")

        el-select(
          v-model="selectedType"
          placeholder="Seleccione un tipo"
          filterable
          clearable
          default-first-option
        )
          el-option(
          v-for="type in massiveActionsTypes",
          :key="type.value",
          :label="type.label",
          :value="type.value"
        )

    spinner(v-if='isLoading')
    h2.no-alerts(v-else-if='!isLoading && !filteredMassiveActions.length') No hay resultados
      
    table(v-else)
      thead
        tr
          th Accion
          th Fecha
          th Autor
          th Estado
          th Resultado

      tbody
        tr.massiveAction(v-for='massiveAction in filteredMassiveActions' :key='massiveAction._id' @click='showModal(massiveAction)')
          td {{ getLabel(massiveAction.type) }}
          td {{ massiveAction.createdAt | formatDateAndTime }}
          td {{ getFullName(massiveAction.createdBy) }}
          td {{ getStatus(massiveAction.status )}}
          td.icon
            micon(v-if='massiveAction.status === "ended" && massiveAction.errorList.length' name='alert')
            micon(v-else-if='massiveAction.status === "ended" && !massiveAction.errorList.length' name='check')
            micon(v-else name='history')


    MassiveActionsHistoryModal(v-if='selectedMassiveAction' :massiveAction='selectedMassiveAction' :showDialogModal='this.isModalVisible' @close='closeModal')
</template>

<style lang="scss">
.massiveAction {
  cursor: pointer;
}
</style>
