<script>
import debMediaService from "@/services/debmedia";
import { theme } from "@/services/config";

export default {
  name: "ModalDerivation",

  data() {
    return {
      isLoading: false,
      selectedDerivableQueue: undefined,
      theme,
    };
  },

  props: {
    showDialogModal: {
      type: Boolean,
      required: true,
    },
    selectedAppointmentId: {
      type: String,
      required: true,
    },
    derivableQueues: {
      type: Array,
    },
    currentAppointment: {
      type: Object,
    },
  },

  methods: {
    close() {
      this.$emit("close");
    },

    submit() {
      this.derivateAppointment();
    },

    derivateAppointment() {
      if (theme === "ccr") {
        this.isLoading = true;
        if (this.selectedAppointmentId !== this.currentAppointment?._id) {
          this.$message.error(
            "No puedes ejecutar esta acción. Finaliza el turno activo primero."
          );
          this.isLoading = false;
          this.close();
          return;
        }

        debMediaService
          .derivateAppointment({
            appointmentId: this.selectedAppointmentId,
            queueId: this.selectedDerivableQueue,
          })
          .then((derivatedAppointment) => {
            this.$emit("appointmentDerivated", derivatedAppointment);
            this.isLoading = false;
          })
          .then(() => this.$message.success("Turno derivado con éxito"))
          .then(() => this.close());
      }
    },
  },
};
</script>

<template lang="pug">
el-dialog(:visible.sync="showDialogModal", :before-close="close", title="Derivar turno")
  ValidationObserver(v-slot="{handleSubmit}")
    form(@submit.prevent="handleSubmit(submit)")
      ValidationProvider(name="Cola", rules="required", v-slot="{ errors }")
        .el-dialog__body
          el-select(
            v-model="selectedDerivableQueue"
            placeholder="Seleccionar cola"
            clearable
          )
            el-option(
              v-for="derivableQueue in derivableQueues"
              :key="derivableQueue.id"
              :label="derivableQueue.name"
              :value="derivableQueue.id"
            )
        span.has-error {{ errors[0] }} 

      .el-dialog__footer
        el-button(type="primary" native-type="submit" :loading="isLoading" :disabled="!selectedDerivableQueue") Derivar
</template>
