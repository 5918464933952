<script>
import { mapGetters } from "vuex";

import leave from "@/mixins/leave";
import institutionService from "@/services/institution";
import Spinner from "@/components/ui/Spinner";
import InputTag from "@/components/ui/InputTag";

export default {
  name: "ModalInstitution",
  mixins: [leave],

  components: {
    Spinner,
    InputTag,
  },

  props: {
    showDialogModal: {
      type: Boolean,
    },
    currentInstitution: {
      type: Object,
    },
  },

  data() {
    return {
      isLoading: false,
      isPosting: false,
      institutionId: null,
      institution: {
        address: {
          betweenStreets: ["", ""],
        },
      },
      isFetchingInstitution: false,
      isUpdate: !!this.currentInstitution?._id,
      isShowed: this.showDialogModal,
    };
  },

  computed: {
    ...mapGetters(["isAdmin", "isDoctor"]),
  },

  created() {
    if (this.isUpdate) {
      this.institution = this.currentInstitution;
    }
  },

  mounted() {
    document.addEventListener("keyup", this.escape);
  },

  beforeDestroy() {
    document.removeEventListener("keyup", this.escape);
  },

  methods: {
    escape(event) {
      if (event.keyCode == 27) this.close();
    },

    submit() {
      if (this.isUpdate) {
        this.updateInstitution();
      } else {
        this.createInstitution();
      }
    },

    createInstitution() {
      this.isPosting = true;

      institutionService
        .createInstitution(this.institution)
        .then((institution) => {
          this.institutionId = institution._id;
          this.$bus.$emit("institution-created", institution);
          this.close();
        })
        .catch(() => {
          this.isPosting = false;
        });
    },

    updateInstitution() {
      this.isPosting = true;

      institutionService
        .updateInstitution(this.institution)
        .then((institution) => {
          this.institutionId = institution._id;
          this.$bus.$emit("institution-updated", institution);
          this.close();
        })
        .catch(() => {
          this.isPosting = false;
        });
    },

    close() {
      this.isPosting = false;
      this.isShowed = false;
      this.$emit("close");
    },
  },
};
</script>

<template lang="pug">
    ValidationObserver(v-slot="{handleSubmit}")
      form.modal(@submit.prevent="handleSubmit(submit)")
        header.modal__header
          h2.modal__title Institución
          .modal__actions
            el-button(type="info" @click="close()") Cancelar
            el-button.border(type="primary" native-type="submit" :loading="isPosting") Guardar
        p.modal__subtitle Los campos con (*) son obligatorios
        .modal__content(v-if="isFetchingInstitution")
          spinner
        .modal__content
          .modal__block
            .modal__section
              .modal__sign.sign
                .sign__icon
                  micon(name="assignment")
                h3.sign__title Detalle
              article.modal__fields
                .modal__row
                  fieldset.modal__field
                    label.label * Nombre
                    ValidationProvider(name="Name" rules="required" v-slot="{ errors }")
                      el-input(v-model="institution.name")
                      span.has-error {{errors[0]}}
                  fieldset.modal__field
                    ValidationProvider(name="Abbreviation" rules="required" v-slot="{ errors }")
                      label.label * Abreviación
                      el-input(v-model="institution.abbreviation")
                      span.has-error {{errors[0]}}
                  fieldset.modal__field
                    label.label ID externo
                    el-input(v-model="institution.publicId")
                .modal__row
                    fieldset.modal__field
                        label.label Email
                        ValidationProvider(name="Email", rules="email", v-slot="{ errors }")
                          el-input(type="email", v-model="institution.email")
                          span.has-error {{ errors[0] }}
                    fieldset.modal__field
                      label.label Teléfono
                      el-input(v-model="institution.phone", name="Teléfono")  
          .modal__block
            .modal__section
              .modal__sign.sign
                .sign__icon
                    micon(name="location_on")
                h3.sign__title Dirección
              article.modal__fields.address
                    .modal__row
                      fieldset.modal__field
                        label.label * Calle
                        ValidationProvider(name="Calle" rules="required" v-slot="{ errors }")
                          el-input(v-model="institution.address.street")
                          span.has-error {{errors[0]}}
                      fieldset.modal__field  
                        label.label * Número
                        ValidationProvider(name="Numero" rules="required" v-slot="{ errors }")
                          el-input(v-model="institution.address.buildingNumber")
                          span.has-error {{errors[0]}}
                      fieldset.modal__field  
                        label.label Piso
                        el-input(v-model="institution.address.apartmentFloor")
                    .modal__row
                      fieldset.modal__field  
                        label.label Número de puerta
                        el-input(v-model="institution.address.apartmentNumber")
                      fieldset.modal__field  
                        label.label * Código postal
                        ValidationProvider(name="Código postal" rules="required" v-slot="{ errors }")
                          el-input(v-model="institution.address.postalCode")
                          span.has-error {{errors[0]}}
                    .modal__row
                      fieldset.modal__field  
                        label.label * Ciudad
                        ValidationProvider(name="Ciudad" rules="required" v-slot="{ errors }")
                          el-input(v-model="institution.address.city")
                          span.has-error {{errors[0]}}
                      fieldset.modal__field  
                        label.label * Distrito
                        ValidationProvider(name="Distrito" rules="required" v-slot="{ errors }")
                          el-input(v-model="institution.address.district")
                          span.has-error {{errors[0]}}
                      fieldset.modal__field  
                        label.label Barrio
                        el-input(v-model="institution.address.neighborhood")
                    .modal__row
                      fieldset.modal__field  
                        label.label Entre Calle 1
                        el-input(v-model="institution.address.betweenStreets[0]")
                      fieldset.modal__field  
                        label.label Entre Calle 2
                        el-input(v-model="institution.address.betweenStreets[1]")                           
</template>

<style lang="scss" scoped>
.location {
  .micon {
    margin-right: 10px;
  }
}

.modal {
  z-index: 9999;
}

.event-detail-modal {
  h2 {
    font-weight: bold;
    margin-bottom: 20px;
  }

  h3 {
    margin: 0 0 20px;
  }

  .form-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    .description {
      word-break: break-word;
      font-weight: bold;
      color: $primary;
      margin-bottom: 10px;
    }

    .response {
      margin-left: 20px;
      display: list-item;
      list-style-type: "→ ";
    }
  }

  .view-task {
    padding: 1rem;
  }
}

@media (min-width: 446px) {
  .container {
    flex-direction: row !important;
    justify-content: space-between;
  }

  .betweenStreet {
    width: 48.5% !important;

    &:last-child {
      margin-top: 0 !important;
    }
  }
}
</style>
