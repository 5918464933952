import api from "./api";
import store from "@/store";
import { USER_ROLES } from "@/services/constants";

const userService = {};
const endpoint = () => `/users`;

userService.create = function (user) {
  return api.post(endpoint(), user);
};

userService.get = function (query) {
  return api
    .get(endpoint(), {
      params: { ...query, sort: { lastName: 1, firstName: 1 } },
    })
    .then((res) => res.data);
};

userService.getDoctors = function (query = {}) {
  return userService.get({
    ...query,
    roles: [USER_ROLES.DOCTOR.id],
    sort: { lastName: 1, firstName: 1 },
  });
};

userService.getDoctorsByInstitution = function (institutionId) {
  return api
    .get(`doctors/institution/${institutionId}`, {
      params: { sort: { lastName: 1, firstName: 1 } },
    })
    .then((res) => res.data);
};

userService.getManagers = function (query = {}) {
  return userService.get({
    ...query,
    roles: [USER_ROLES.MANAGER.id],
    sort: { lastName: 1, firstName: 1 },
  });
};

userService.getById = function (id) {
  return api.get(`${endpoint()}/${id}`).then((res) => res.data);
};

userService.update = function (user) {
  return api.put(`${endpoint()}/${user._id}`, user);
};

userService.updateMe = function (user) {
  return api.put(`${endpoint()}/${user._id}`, user).then(() => {
    store.commit("setUser", user);
  });
};

userService.updatePassword = function (user) {
  return api.put(`/users/${user._id}/password-reset`, user);
};

userService.lock = function (id) {
  return api.put(`${endpoint()}/${id}/lock`);
};

userService.unlock = function (id) {
  return api.put(`${endpoint()}/${id}/unlock`);
};

userService.delete = function (id) {
  return api.delete(`${endpoint()}/${id}`);
};

userService.getById = function (id) {
  return api.get(`${endpoint()}/${id}`).then((res) => res.data);
};

export default userService;
