<script>
import { enableChat, theme, isMassiveEnabled } from "@/services/config";
import { mapGetters } from "vuex";

export default {
  name: "SubNav",

  props: {
    nav: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      enableChat,
      theme,
      isMassiveEnabled,
    };
  },

  methods: {
    isRoute(name) {
      return this.$route.name === name;
    },
  },

  computed: {
    ...mapGetters(["isAdmin", "isAssistant", "isDoctor"]),

    isTreatmentSubNavActive() {
      return (
        this.$route.name === "treatment" ||
        this.$route.name === "treatment-tasks"
      );
    },

    isLabelSubNavActive() {
      return this.$route.name === "labels";
    },

    isConfigSubNavActive() {
      return (
        this.$route.name !== "treatment-tasks" &&
        (this.$route.name.includes("treatment-") ||
          this.$route.name === "create-vital" ||
          this.$route.name === "update-vital")
      );
    },
  },
};
</script>

<template lang="pug">
    .subnav(v-if="nav === 'dashboard'")
      nav.subnav__list
        router-link.subnav__item(:to="{ name: 'dashboard-alerts' }") Todos
        router-link.subnav__item(:to="{ name: 'dashboard-attentions' }") Atenciones
        router-link.subnav__item(v-if="isAdmin || isAssistant" :to="{ name: 'assistant-evaluation' }") Derivaciones Asesores
        router-link.subnav__item(v-if="isDoctor && enableChat" :to="{ name: 'dashboard-conversations' }") Conversaciones
        router-link.subnav__item(v-if="(isAdmin || isAssistant) && enableChat" :to="{ name: 'dashboard-conversations-unreplied' }") Auditoría Chat
    .subnav(v-else-if="nav === 'treatment'")
      nav.subnav__list
        router-link.subnav__item(:to="{ name: 'treatment' }", :class="{ 'router-link-exact-active': isTreatmentSubNavActive }", exact) Protocolos
        el-dropdown(trigger="click")
          a.el-dropdown-link.subnav__item(:to="{ name: 'treatment-medication' }", :class="{ 'router-link-exact-active': isConfigSubNavActive }", exact)
            | Configuración
            i.el-icon-arrow-down.el-icon--right
          el-dropdown-menu(slot="dropdown")
            el-dropdown-item(:disabled="isRoute('treatment-medication')" :class="{'active': isRoute('treatment-medication')}")
              router-link(:to="{ name: 'treatment-medication' }") Recordatorios
            el-dropdown-item(:disabled="isRoute('treatment-appointment')" :class="{'active': isRoute('treatment-appointment')}")
              router-link(:to="{ name: 'treatment-appointment' }") Turnos
            el-dropdown-item(:disabled="isRoute('treatment-recommendation')" :class="{'active': isRoute('treatment-recommendation')}")
              router-link(:to="{ name: 'treatment-recommendation' }") Recomendaciones
            el-dropdown-item(:disabled="isRoute('treatment-exercise')" :class="{'active': isRoute('treatment-exercise')}")
              router-link(:to="{ name: 'treatment-exercise' }") Ejercicios
            el-dropdown-item(:disabled="isRoute('treatment-vital')" :class="{'active': isRoute('treatment-vital') || isRoute('update-vital')}")
              router-link(:to="{ name: 'treatment-vital' }") Registros
            el-dropdown-item(:disabled="isRoute('treatment-report')" :class="{'active': isRoute('treatment-report')}")
              router-link(:to="{ name: 'treatment-report' }") Reportes
            el-dropdown-item(:disabled="isRoute('treatment-checkin')" :class="{'active': isRoute('treatment-checkin')}")
              router-link(:to="{ name: 'treatment-checkin' }") Check-in
        //- router-link.subnav__item(:to="{ name: 'label' }", :class="{ 'router-link-exact-active': isLabelSubNavActive }", exact) Etiquetas 
    .subnav(v-else-if="nav === 'settings'")
      nav.subnav__list
        router-link.subnav__item(:to="{ name: 'settings' }", :class="{'active': isRoute('settings')}") Administración
        router-link.subnav__item(v-if='isMassiveEnabled' :to="{ name: 'settings-massive' }", :class="{'active': isRoute('settings-massive')}") Carga Masiva
    .subnav(v-else-if="nav=== 'appointment'")
      nav.subnav__list
        router-link.subnav__item(:to="{ name: 'appointment' }", :class="{'active': isRoute('appointment')}") Calendario
        router-link.subnav__item(v-if="isAdmin" :to="{ name: 'appointment-agendas' }", :class="{'active': isRoute('appointment-agendas')}") Agendas
        router-link.subnav__item(:to="{ name: 'appointment-relocation' }", :class="{'active': isRoute('appointment-relocation')}") Turnos indefinidos
</template>
