<script>
import Spinner from "@/components/ui/Spinner";
import { mapGetters } from "vuex";
import { format, addMilliseconds } from "date-fns";
import userService from "@/services/user";
import helperService from "@/services/helper";
import medicalSpecialtyService from "@/services/medical-specialty";
import institutionService from "@/services/institution";
import slotService from "@/services/slot";
import pagination from "@/mixins/pagination";
import { SLOT_ATTENTION_TYPES } from "@/services/constants";
import appointmentService from "@/services/appointment";

export default {
  name: "AppointmentsRelocationView",

  mixins: [pagination],

  components: {
    Spinner,
  },

  data() {
    return {
      doctors: [],
      medicalSpecialties: [],
      institutions: [],
      slots: [],
      selectedDoctorId: undefined,
      selectedInstitutionId: undefined,
      selectedMedicalSpecialtyId: undefined,
      isLoading: false,
    };
  },

  computed: {
    ...mapGetters(["isAdmin", "isDoctor", "user"]),
  },

  watch: {
    selectedDoctorId() {
      this.getRelocatedAppointments();
    },
    selectedInstitutionId() {
      this.getRelocatedAppointments();
    },
    selectedMedicalSpecialtyId() {
      this.getRelocatedAppointments();
    },
  },

  created() {
    this.getDoctors();
    this.getMedicalSpecialties();
    this.getInstitutions();
    this.getRelocatedAppointments();
  },

  methods: {
    async getDoctors() {
      this.isLoading = true;
      try {
        const { docs } = await userService.getDoctors();
        this.doctors = docs;
      } finally {
        this.isLoading = false;
      }
    },

    async getMedicalSpecialties() {
      this.isLoading = true;
      try {
        const medicalSpecialties =
          await medicalSpecialtyService.getMedicalSpecialties();
        this.medicalSpecialties = medicalSpecialties;
      } finally {
        this.isLoading = false;
      }
    },

    async getInstitutions() {
      this.isLoading = true;
      try {
        const institutions = await institutionService.getInstitutions();
        this.institutions = institutions;
      } finally {
        this.isLoading = false;
      }
    },

    async getRelocatedAppointments() {
      this.isLoading = true;
      try {
        const query = {
          relocatedAt: { $exists: true },
          startsAt: { $gte: new Date() },
          ...(this.isDoctor && { doctor: this.user._id }),
          populate: [
            {
              path: "appointments",
              populate: [
                {
                  path: "videoRoom",
                },
                {
                  path: "patient",
                },
                {
                  path: "medicalSpecialty",
                },
              ],
            },
            { path: "doctor" },
            { path: "medicalSpecialties" },
            { path: "institution" },
          ],
        };

        this.selectedDoctorId && (query.doctor = this.selectedDoctorId);
        this.selectedInstitutionId &&
          (query.institution = this.selectedInstitutionId);
        this.selectedMedicalSpecialtyId &&
          (query.medicalSpecialties = this.selectedMedicalSpecialtyId);

        const aggregationQuery = {
          ...query,
          offset: this.pagination.offset,
          limit: this.pagination.limit,
        };

        const res = await slotService.getSlotsByDate(aggregationQuery);
        this.slots = res.docs;
        this.pagination.total = res.total;
      } finally {
        this.isLoading = false;
      }
    },

    getFullName(user) {
      return helperService.getFullName(user);
    },

    getAttentionTypeLabel(attentionType) {
      return (
        SLOT_ATTENTION_TYPES.find((elem) => elem.value === attentionType)
          .name || "N/A"
      );
    },

    cancelRelocatedAppointment({ appointment, slot }) {
      appointmentService
        .cancelAppointment(appointment._id)
        .then(() => this.$message.success("Se canceló el turno con éxito"))
        .then(() => {
          const index = this.slots.findIndex((elem) => elem._id === slot._id);
          this.slots.splice(index, 1);
        });
    },

    getStartAndEndHours(startsAt, endsAt) {
      const parsedStartHour = format(new Date(startsAt), "HH:mm");

      let parsedEndHour = format(addMilliseconds(new Date(endsAt), 1), "HH:mm");

      // workaround
      if (parsedEndHour === "00:00") {
        parsedEndHour = "24:00";
      }
      // workaround

      return `${parsedStartHour} - ${parsedEndHour}`;
    },
  },
};
</script>

<template lang="pug">
  section
      header.headline
        .headline__title
          h1
            | Turnos indefinidos
            small(v-if="!isLoading && pagination.total")  ({{ pagination.total }})

          hr
        .headline__actions
          el-select(
              v-model="selectedInstitutionId"
              placeholder="Institución"
              filterable
              clearable
              default-first-option
          )
            el-option(
                v-for="insitution in institutions"
                :key="insitution._id"
                :label="insitution.name"
                :value="insitution._id"
            )

          el-select(
              v-if="!isDoctor"
              v-model="selectedDoctorId"
              placeholder="Médico"
              filterable
              clearable
              default-first-option
          )
            el-option(
              v-for="doctor in doctors"
              :key="doctor._id"
              :label="`${getFullName(doctor)}`"
              :value="doctor._id"
            )

          el-select(
              v-model="selectedMedicalSpecialtyId"
              placeholder="Especialidades"
              filterable
              clearable
              default-first-option
          )
            el-option(
                v-for="medicalSpecialty in medicalSpecialties"
                :key="medicalSpecialty._id"
                :label="medicalSpecialty.name"
                :value="medicalSpecialty._id"
            )
          
      .box.box--with-subnav
        .box__content--stretch
          spinner(v-if="isLoading")
          h2.no-alerts(v-if="!isLoading && !slots.length") No se encontraron turnos
          table(v-if="!isLoading && slots.length")
            thead
              tr
                th Institución
                th Doctor
                th Especialidad
                th Fecha
                th Horario
                th Tipo de turno
                th Acciones
            tbody
              template(v-for='slot in slots' )
                tr(v-for='appointment in slot.appointments' :key="appointment._id" @click="updateAgenda(slot)")
                  td {{ slot.institution.name }}
                  td {{ getFullName(slot.doctor) }}
                  td {{ appointment.medicalSpecialty.name }}
                  td {{ appointment.startsAt | formatDate }}
                  td {{ getStartAndEndHours(appointment.startsAt, appointment.endsAt)}}
                  td {{ getAttentionTypeLabel(appointment.attentionType) }}
                  td.actions
                    .actions-container
                      el-dropdown(trigger="click")
                        .button.button--action.el-dropdown-link(@click.stop)
                          micon(name="more_horiz")
                        el-dropdown-menu(slot="dropdown")
                          el-dropdown-item
                            div(@click="cancelRelocatedAppointment({appointment, slot})")
                              micon(name="delete_forever")
                              | Cancelar turno
      pagination(
        :isLoading="isLoading"
        :limit="pagination.limit"
        :total="pagination.total"
        @pagination="setPagination"
      )
</template>

<style lang="scss" scoped>
@import "@/styles/settings/index.scss";
@import "@/styles/tools/index.scss";

tbody .free:hover {
  cursor: pointer;
}

h2 {
  margin-top: 20px;
  text-align: center;
}

.empty-row {
  td {
    border: none;
  }
}
</style>
