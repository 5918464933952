<script>
import { mapGetters } from "vuex";
import { merge } from "lodash";

import leave from "@/mixins/leave";

import patientService from "@/services/patient";
import companyService from "@/services/company";
import fileUploadConfigService from "@/services/fileupload-config";
import segmentService from "@/services/segment";
import { PROVINCES, DOC_TYPES, DOC_TYPE_DEFAULT } from "@/services/constants";
import userService from "@/services/user";
import episodeService from "@/services/episode";
import medicalInsuranceService from "@/services/medical-insurance";
import { capitalize } from "@/services/helper";

import Spinner from "@/components/ui/Spinner";
import FileUpload from "@/components/ui/FileUpload";
import InputTag from "@/components/ui/InputTag";
import { enableScores } from "@/services/config";

export default {
  name: "ModalPatientDetail",
  mixins: [leave],

  components: {
    Spinner,
    FileUpload,
    InputTag,
  },

  props: {
    showDialogModal: {
      type: Boolean,
      default: false,
    },
    selectedPatientId: {
      type: String,
    },
  },

  data() {
    return {
      capitalize,
      useScores: enableScores,
      medicalInsurances: [],
      companies: [],
      patientId: this.selectedPatientId || undefined,
      isUpdate: !!this.selectedPatientId,
      docTypes: DOC_TYPES,
      doctors: [],
      originalEmail: "",
      patient: {
        publicGroupId: "",
        publicId: "",
        doctor: null,
        firstName: "",
        lastName: "",
        bornAt: "",
        email: "",
        phone: "",
        secondaryPhone: "",
        gender: "m",
        governmentId: {
          type: DOC_TYPE_DEFAULT,
          number: "",
        },
        address: {
          street: "",
          city: "",
          district: "Buenos Aires",
          country: "Argentina",
          postalCode: "",
          betweenStreets: ["", ""],
        },
        patient: {
          medicalInsurance: {
            name: "",
            number: "",
            plan: "",
          },
          health: {
            bloodType: "",
            height: "",
            weight: "",
            allergies: [],
            additionalInformation: "",
            diagnosis: "",
          },
        },
      },
      files: [],
      isFetchingPatient: false,
      isPostingPatient: false,
      hasFiles: false,
      provinces: PROVINCES,
      activeEpisode: null,
      originalDoctor: "",
    };
  },

  computed: {
    ...mapGetters(["isAdmin", "isDoctor"]),

    uploadMediaEndpoint() {
      return fileUploadConfigService.getUploadAvatarEndpoint(
        "users",
        this.patientId
      );
    },

    sectors() {
      if (!this.patient.company) {
        return [];
      }

      const company = this.companies.find(
        (c) => c._id === this.patient.company
      );

      return company?.sectors;
    },

    canChangeEmail() {
      if (!this.isUpdate) {
        return true;
      }

      return !this.isDoctor;
    },

    hasEmailChanged() {
      if (!this.isUpdate) {
        return false;
      }

      return this.patient.email !== this.originalEmail;
    },

    showDeviceSection() {
      return this.isAdmin && this.isUpdate && this.patient.device;
    },

    isDoctorRequired() {
      return (
        (this.isUpdate && this.activeEpisode) ||
        (!this.isUpdate && !this.activeEpisode)
      );
    },
  },

  created() {
    this.getPatient();
    this.getDoctors();
    this.getMedicalInsurances();

    if (this.useScores) {
      this.$set(this.patient, "company", null);
      this.$set(this.patient, "companySector", null);
      this.getCompanies();
    }
  },

  mounted() {
    document.addEventListener("keyup", this.escape);
  },

  beforeDestroy() {
    document.removeEventListener("keyup", this.escape);
  },

  methods: {
    close() {
      this.$emit("close");
    },

    async getActiveEpisode() {
      const activeEpisode = await episodeService.getActive(this.patientId);
      if (activeEpisode) {
        this.activeEpisode = activeEpisode;
        // this.patient.doctor = activeEpisode.doctor;
      }
    },

    escape(event) {
      if (event.keyCode == 27) this.close();
    },

    submit() {
      this.createOrUpdatePatient();
    },

    createOrUpdatePatient() {
      const eventName = this.isUpdate ? "Patient Updated" : "Patient Created";
      segmentService.track({ name: eventName });

      this.isPostingPatient = true;
      this.showLeaveGuard = false;
      const serviceCall = this.isUpdate
        ? patientService.update
        : patientService.create;

      if (!this.patient.doctor) {
        this.patient.doctor = null;
      }

      const promises = [
        serviceCall({
          ...this.patient,
          publicId: undefined,
        }),
      ];

      const hasDoctorChanged = this.patient.doctor !== this.originalDoctor;

      if (this.isUpdate && this.activeEpisode && hasDoctorChanged) {
        const episodeId = this.activeEpisode._id;
        const patientId = this.patientId;
        const body = { doctor: this.patient.doctor };
        promises.push(episodeService.update({ episodeId, patientId, body }));
      }

      return Promise.all(promises)
        .then(([patient]) => {
          this.patientId = this.patientId || patient._id;
          // patientUpdate.doctors = [
          //   this.doctors.find(({ _id }) => this.patient.doctor === _id),
          // ];
          const patientUpdate = patient || this.patient;

          this.$bus.$emit("patient-update", patientUpdate);

          if (this.hasFiles) {
            this.handleUploadFiles();
          } else {
            this.close();
          }
        })
        .catch(() => {
          this.isPostingPatient = false;
        });
    },

    getPatient() {
      if (this.isUpdate) {
        this.isLoading = true;
        this.isFetchingPatient = true;
        patientService
          .getById(this.patientId)
          .then((patient) => {
            patient.doctor = patient.doctor ? patient.doctor._id : null;
            patient.address.betweenStreets =
              patient.address.betweenStreets || [];

            this.originalEmail = patient.email;

            this.patient = merge({}, this.patient, {
              ...patient,
              doctors: patient.doctors.map((elem) => elem._id),
            });
            this.originalDoctor = patient.doctor;
          })
          .finally(() => {
            this.getActiveEpisode();
            this.isFetchingPatient = false;
            this.isLoading = false;
          });
      }
    },

    getDoctors() {
      userService.getDoctors().then(({ docs }) => (this.doctors = docs));
    },

    getCompanies() {
      companyService.get().then(({ docs }) => (this.companies = docs));
    },

    getMedicalInsurances() {
      medicalInsuranceService
        .getMedicalInsurances()
        .then(
          (medicalInsurances) => (this.medicalInsurances = medicalInsurances)
        );
    },

    getModalName() {
      return this.isUpdate ? "Actualizar Paciente" : "Nuevo Paciente";
    },

    handleUploadFiles() {
      this.$refs.fileUpload && this.$refs.fileUpload.startUpload();
    },

    getFiles() {
      return this.patient.avatar && this.patient.avatar.key
        ? [this.patient.avatar]
        : [];
    },

    getDropdownOptions() {
      return {
        method: "put",
        paramName: "avatar",
        maxFiles: 1,
      };
    },

    uploadFilesSuccess() {
      this.close();
    },

    uploadFilesError() {
      this.isPostingPatient = false;
    },

    onFileAdded() {
      this.hasFiles = true;
    },

    onFileRemoved() {
      this.hasFiles = false;
      this.patient.avatar = null;
    },
  },
};
</script>

<template lang="pug">
ValidationObserver(v-slot="{handleSubmit}")
  form.modal(@submit.prevent="handleSubmit(submit)")
    header.modal__header
      h2.modal__title {{ getModalName() }}
      .modal__actions
        el-button(type="info" @click="close") Cancelar
        el-button.border(type="primary" native-type="submit" :loading="isPostingPatient") Guardar
    p.modal__subtitle Los campos con (*) son obligatorios
    .modal__content(v-if="isFetchingPatient")
      spinner
    .modal__content(v-else)
      // Personal Info Section
      .modal__block
        .modal__section
          .modal__sign.sign
            .sign__icon
              micon(name="person")
            h3.sign__title Información Personal
          article.modal__fields
            .modal__row
              fieldset.modal__field
                label.label * Nombre
                ValidationProvider(name="nombre" rules="required" v-slot="{ errors }")
                  el-input(v-model="patient.firstName")
                  span.has-error {{errors[0]}}
              fieldset.modal__field
                label.label * Apellido
                ValidationProvider(name="apellido" rules="required" v-slot="{errors}")
                  el-input(v-model="patient.lastName")
                  span.has-error {{errors[0]}}
            .modal__row
              fieldset.modal__field
                label.label * Tipo de Documento
                el-select#government-id-type(v-model="patient.governmentId.type" :disabled="isUpdate" placeholder="" default-first-option filterable)
                  el-option(v-for="docType in docTypes" :key="docType" :value="docType")
              fieldset.modal__field
                label.label * Número de Documento
                ValidationProvider(name="número de documento" rules="required" v-slot="{errors}")
                  el-input(v-model="patient.governmentId.number")
                  span.has-error {{errors[0]}}
            .modal__row
              fieldset.modal__field
                label.label * Fecha de Nacimiento
                ValidationProvider(name="fecha de nacimiento" rules="required|priorToday" v-slot="{errors}")
                  el-date-picker(v-model="patient.bornAt" format="dd/MM/yyyy" placeholder="DD/MM/YYYY" lang="es" name="fecha de nacimiento")
                  span.has-error {{errors[0]}}
              fieldset.modal__field
                label.label * Género
                el-radio(v-model="patient.gender" label="m" checked) Masculino
                el-radio(v-model="patient.gender" label="f") Femenino
                el-radio(v-model="patient.gender" label="x") Otro
          article.modal__fields.modal__fields--small
            .modal__row
              fieldset.modal__field
                label.label Foto
                file-upload(
                  ref="fileUpload",
                  type="image"
                  :url="uploadMediaEndpoint",
                  :files="getFiles()",
                  :dropzone-options="getDropdownOptions()"
                  @file-added="onFileAdded",
                  @files-removed="onFileRemoved",
                  @fileupload-success="uploadFilesSuccess"
                  @fileupload-error="uploadFilesError"
                )

      // Health Section
      .modal__block
        .modal__section
          .modal__sign.sign
            .sign__icon
              micon(name="favorite")
            h3.sign__title Cobertura
          article.modal__fields
            //- .modal__row
              fieldset.modal__field
                label.label Tipo de Sangre:
                el-select#blood-type(v-model="patient.patient.health.bloodType" placeholder="" filterable clearable default-first-option)
                  el-option(label="O+" value="O+")
                  el-option(label="A" value="A")
                  el-option(label="B" value="B")
                  el-option(label="O" value="O")
                  el-option(label="AB" value="AB")
              fieldset.modal__field
                label.label Altura (m):
                el-input(type="number", step="any", min="0", v-model="patient.patient.health.height", name="height")
              fieldset.modal__field
                label.label Peso (kg):
                el-input(type="number", step="any", min="0", v-model="patient.patient.health.weight", name="weight")
              fieldset.modal__field
                label.label Alergias:
                input-tag#allergies(v-model="patient.patient.health.allergies")
            .modal__row
              fieldset.modal__field(v-if="isAdmin")
                label.label ID Proveedor
                el-input(v-model="patient.publicId", name="ID Público", disabled)
              fieldset.modal__field
                  label.label * Seguro Médico:
                  ValidationProvider(name="seguro médico" rules="required" v-slot="{errors}")
                    el-select(v-model="patient.patient.medicalInsurance.value")
                      el-option(
                        v-if="medicalInsurances.length"
                        v-for="medicalInsurance in medicalInsurances"
                        :key="medicalInsurance._id"
                        :label="capitalize(medicalInsurance.name)"
                        :value="medicalInsurance._id"
                      )
                    span.has-error {{errors[0]}}
              fieldset.modal__field
                label.label * Número de socio:
                el-input(v-model="patient.patient.medicalInsurance.number")
              fieldset.modal__field
                label.label ID Grupo familiar:
                el-input(v-model="patient.patient.publicGroupId" :disabled="isUpdate")
            .modal__row
              fieldset.modal__field
                label.label Médico Responsable:
                el-select#doctor-assigned(
                  v-model="patient.doctors"
                  placeholder=""
                  filterable
                  clearable
                  default-first-option
                  multiple
                  :disabled="isUpdate && !activeEpisode"
                )
                  el-option(
                    v-for="doctor in doctors"
                    :key="doctor._id"
                    :label="`${doctor.lastName}, ${doctor.firstName}`"
                    :value="doctor._id"
                  )

              //- Completar
              fieldset.modal__field
                label.label Manager:
                el-input(disabled)
              //- fieldset.modal__field
                label.label Información Adicional:
                el-input(v-model="patient.patient.health.additionalInformation" name="additionalInformation")

            .modal__row
              fieldset.modal__field
                label.label Motivo del llamado:
                el-input(v-model="patient.patient.health.diagnosis" type="textarea")

      // Company Section
      .modal__block(v-if="useScores")
        .modal__section
          .modal__sign.sign
            .sign__icon
              micon(name="videogame")
            h3.sign__title Scoring
          article.modal__fields
            .modal__row
              fieldset.modal__field
                label.label * Empresa
                el-select(v-model="patient.company" placeholder="" filterable clearable default-first-option)
                  el-option(
                    v-for="c in companies"
                    :key="c._id"
                    :label="c.name"
                    :value="c._id"
                  )
              fieldset.modal__field
                label.label * Sector
                el-select(:disabled="!patient.company" v-model="patient.companySector" placeholder="" filterable clearable default-first-option)
                  el-option(
                    v-for="s in sectors"
                    :key="s"
                    :label="s"
                    :value="s"
                  )

      // Contact Section
      .modal__block
        .modal__section
          .modal__sign.sign
            .sign__icon
              micon(name="forum")
            h3.sign__title Contacto
          article.modal__fields
            .modal__row
              fieldset.modal__field
                label.label * Email
                ValidationProvider(name="email" rules="required|email" v-slot="{errors}")
                  el-input(type="email", v-model="patient.email", :disabled="!canChangeEmail")
                  span.has-error {{errors[0]}}
                  span.has-warning(v-show="hasEmailChanged") Al modificar el email, el usuario debera volver a validar la invitación
              fieldset.modal__field
                label.label * Teléfono
                ValidationProvider(name="teléfono" rules="required" v-slot="{errors}")
                  el-input(v-model="patient.phone")
                  span.has-error {{errors[0]}}
              fieldset.modal__field
                label.label Teléfono secundario
                el-input(v-model="patient.secondaryPhone")

      // Address Section
      .modal__block
        .modal__section
          .modal__sign.sign
            .sign__icon
              micon(name="location_on")
            h3.sign__title Dirección
          article.modal__fields
            .modal__row
              fieldset.modal__field
                label.label Dirección:
                el-input(v-model="patient.address.street", name="street")
              fieldset.modal__field
                label.label Número:
                el-input(v-model="patient.address.buildingNumber", name="buildingNumber")
              fieldset.modal__field
                label.label Número de piso:
                el-input(v-model="patient.address.apartmentFloor", name="apartmentFloor")
              fieldset.modal__field
                label.label Número apartamento:
                el-input(v-model="patient.address.apartmentNumber", name="apartmentNumber")
            .modal__row
              fieldset.modal__field
                label.label * Localidad:
                el-input(v-model="patient.address.city")
              fieldset.modal__field
                label.label Entre calle 1:
                el-input(v-model="patient.address.betweenStreets[0]", name="betweenStreet1")
              fieldset.modal__field
                label.label Entre calle 2:
                el-input(v-model="patient.address.betweenStreets[1]", name="betweenStreet2")
            .modal__row
              fieldset.modal__field
                label.label Barrio:
                el-input(v-model="patient.address.neighborhood", name="neighborhood")
              fieldset.modal__field
                label.label * Provincia:
                el-select#province(v-model="patient.address.district" placeholder="" filterable default-first-option)
                  el-option(
                    v-for="province in provinces"
                    :key="province"
                    :label="province"
                    :value="province"
                  )
              //- fieldset.modal__field
                label.label * País:
                el-select(v-model="patient.address.country" filterable)
                  el-option(value="Argentina" label="Argentina")
                  el-option(value="Brasil" label="Brasil")
                  el-option(value="Chile" label="Chile")
                  el-option(value="Colombia" label="Colombia")
                  el-option(value="Uruguay" label="Uruguay")
                  el-option(value="" label="province")

              fieldset.modal__field
                label.label Código Postal:
                el-input(v-model="patient.address.postalCode")

      // Device Section
      .modal__block(v-if="showDeviceSection")
        .modal__section
          .modal__sign.sign
            .sign__icon
              micon(name="phone_iphone")
            h3.sign__title Dispositivo
          article.modal__fields
            .modal__row
              fieldset.modal__field
                label.label Sistema Operativo:
                el-input(:value="`${patient.device.os.toUpperCase()} v.${patient.device.osVersion}`", disabled)
              fieldset.modal__field
                label.label Version de la Aplicación:
                el-input(:value="`v.${patient.device.appVersion}`" disabled)
              fieldset.modal__field
                label.label Fecha de Sincronización:
                el-input(:value="patient.device.syncedAt", disabled)
              fieldset.modal__field
                label.label(for="deviceToken") Token de Notificaciones:
                input.input(type="checkbox", id="deviceToken" v-model="patient.device.hasNotificationsToken", disabled)
            //- .modal__row(v-if="patient.device.lastLocation")
            //-   fieldset.modal__field.location
            //-     micon(name="visibility" color="#5BA4DD")
            //-     a(:href="`https://maps.google.com?q=${patient.device.lastLocation.latitude},${patient.device.lastLocation.longitude}`" rel="noopener noreferrer" target="_blank") Ver útlima ubicación
</template>

<style lang="scss" scoped>
.location {
  .micon {
    margin-right: 10px;
  }
}

::v-deep #government-id-type,
.el-select-dropdown__item {
  text-transform: uppercase;
}
</style>
