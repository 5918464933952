<script>
import logo from "@/mixins/logo";
import authService from "@/services/auth";
import { version } from "../../../package.json";

export default {
  name: "Login",

  mixins: [logo],

  data() {
    return {
      isLoading: false,
      version,
      email: "",
      password: "",
    };
  },

  methods: {
    signIn() {
      this.isLoading = true;
      authService
        .authenticate(this.email, this.password)
        .then(() => {
          const path =
            this.$route.query && this.$route.query.redirectUrl
              ? this.$route.query.redirectUrl
              : { name: "dashboard-alerts" };
          this.$router.push(path);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<template lang="pug">
  .login
    .login__container
      form.login__card(@submit.prevent="signIn")
        .login__img
          img(:src="logoUrl")
        el-input#email(placeholder="Email" autofocus="true" v-model="email" autocapitalize="none")
        el-input#password(type="password" placeholder="Contraseña" v-model="password" show-password)
        el-button(type="primary" native-type="submit" :disabled="!email || !password" :loading="isLoading") Iniciar Sesión
        router-link.login__link(:to="{ name: 'password-forgot' }") ¿Olvidaste tu contraseña?

        small.version v.{{ version }}
</template>

<style lang="scss">
.login {
  .version {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }

  .login__img {
    margin-bottom: 30px;
  }

  .el-input {
    margin-bottom: 10px;
  }

  button {
    width: 100%;
  }
}
</style>
