import api from "./api";

const medicalInsuranceService = {};
const medicalInsuranceEndpoint = "/medical-insurance";

medicalInsuranceService.createMedicalInsurance = function (medicalInsurance) {
  return api
    .post(medicalInsuranceEndpoint, medicalInsurance)
    .then(({ data }) => data);
};

medicalInsuranceService.updateMedicalInsurance = function (medicalInsurance) {
  return api
    .put(
      `${medicalInsuranceEndpoint}/${medicalInsurance._id}`,
      medicalInsurance
    )
    .then(({ data }) => data);
};

medicalInsuranceService.getMedicalInsurances = function (queryParams) {
  return api
    .get(medicalInsuranceEndpoint, { params: queryParams })
    .then((res) => res.data);
};

medicalInsuranceService.deleteMedicalInsurance = function (medicalInsuranceId) {
  return api.delete(`${medicalInsuranceEndpoint}/${medicalInsuranceId}`);
};

export default medicalInsuranceService;
