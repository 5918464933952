<script>
import userService from "@/services/user";
import attentionService from "@/services/attention";
import patientService from "@/services/patient";

export default {
  name: "ModalAssignAttention",

  data() {
    return {
      isLoading: false,
      selectedDoctor: "",
      doctors: [],
    };
  },

  props: {
    attention: {
      type: Object,
      required: true,
    },
    showDialogModal: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    this.getDoctors();
  },

  methods: {
    close() {
      this.$emit("close");
    },

    async getDoctors() {
      this.isLoading = true;
      const { docs } = await userService.getDoctors({
        populate: "doctor.medicalSpecialties",
      });
      this.doctors = docs;
      this.isLoading = false;
    },

    getMedicalSpecialtyLabel(doctor) {
      if (!doctor.doctor?.medicalSpecialties?.length) {
        return "";
      }
      return `- ${doctor.doctor.medicalSpecialties[0].name}`;
    },

    async onSave() {
      this.isLoading = true;

      if (!this.attention.patient?.doctors?.includes(this.selectedDoctor)) {
        const updatedDoctors = [...this.attention.patient.doctors];
        updatedDoctors.push(this.selectedDoctor);

        await patientService.update({
          ...this.attention.patient,
          doctors: updatedDoctors,
        });
      }

      const updatedAttention = await attentionService.updateAttention({
        attentionId: this.attention._id,
        body: { ...this.attention, doctor: this.selectedDoctor },
      });

      this.$emit("attention-assigned", updatedAttention);
    },
  },
};
</script>

<template lang="pug">
  el-dialog(name="modal-open-episode", :visible.sync="showDialogModal", :before-close="close", title="Asignar Atención")
    .el-dialog__body
      el-select#doctor-assigned(
        v-model="selectedDoctor"
        placeholder=""
        filterable
        clearable
        default-first-option
      )
        el-option(
          v-for="doctor in doctors"
          :key="doctor._id"
          :label="`${doctor.lastName}, ${doctor.firstName} ${getMedicalSpecialtyLabel(doctor)}`"
          :value="doctor._id"
        )

    .el-dialog__footer
      el-button(type="primary" :loading="isLoading" @click="onSave" :disabled="!selectedDoctor") Asignar
</template>
