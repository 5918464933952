<script>
import Spinner from "@/components/ui/Spinner";
import AttentionTag from "@/components/dashboard/AttentionTag";
import attentionService from "@/services/attention";
import helperService from "@/services/helper";
import pagination from "@/mixins/pagination";
import { ATTENTION_RISKS } from "@/services/constants";
import ModalAssignAttention from "@/components/patient/dashboard/ModalAssignAttention";
import ModalAttention from "@/components/patient/dashboard/ModalAttention";
import { mapGetters } from "vuex";
import { orderBy } from "lodash";

export default {
  name: "DashboardAlerts",

  mixins: [pagination],

  components: {
    AttentionTag,
    Spinner,
    ModalAssignAttention,
    ModalAttention,
  },

  computed: {
    ...mapGetters(["isDoctor", "user"]),
  },

  data() {
    return {
      attentions: [],
      filteredAttentions: [],
      isLoading: false,
      filters: [],
      filterBy: "pending",
      riskOptions: [
        {
          text: "Urgente",
          value: "red",
        },
        {
          text: "Alto",
          value: "orange",
        },
        {
          text: "Medio",
          value: "yellow",
        },
        {
          text: "Bajo",
          value: "green",
        },
        {
          text: "Atentidas",
          value: "completed",
        },
      ],

      selectedAttention: undefined,
      // Overwrite pagination mixin
      pagination: {
        limit: 100,
      },
      modal: undefined,
    };
  },

  created() {
    if (this.isDoctor) {
      this.filterBy = "assigned";
    }
    this.getData();
  },

  watch: {
    filterBy() {
      this.filterAttentions(this.attentions);
    },

    attentions() {
      this.filterAttentions(this.attentions);
    },
  },

  methods: {
    filterAttentions(attentions) {
      attentions = this.sortAttentions(attentions);

      if (this.filterBy === "asigned") {
        this.filteredAttentions = attentions.filter((elem) => !!elem.doctor);
      } else if (this.filterBy === "pending") {
        this.filteredAttentions = attentions.filter((elem) => !elem.doctor);
      } else {
        this.filteredAttentions = attentions;
      }
    },

    sortAttentions(attentions) {
      const order = ["urgent", "high", "medium", "low"];
      return orderBy(
        attentions,
        [
          ({ doctor }) => !!doctor,
          ({ risk }) => order.indexOf(risk),
          "updatedAt",
        ],
        ["asc", "asc", "desc"]
      );
    },

    attentionAssigned(attention) {
      const index = this.attentions.findIndex(
        (elem) => elem._id === attention._id
      );

      this.attentions[index] = attention;
      this.selectedAttention = undefined;
      this.filterAttentions(this.attentions);
    },

    showModal(attention, modal) {
      this.modal = modal;
      this.selectedAttention = attention;
    },

    closeModal() {
      this.modal = undefined;
      this.selectedAttention = undefined;
    },

    getData() {
      this.isLoading = true;

      attentionService
        .getAttentions({
          query: {
            offset: this.pagination.offset,
            limit: this.pagination.limit,
            populate: "patient medicalSpecialty",
            ...(this.isDoctor && { doctor: this.user._id }),
            completedAt: {
              $exists: false,
            },
          },
        })
        .then((data) => {
          if (!data) {
            return;
          }

          const docs = data.docs;
          if (!docs) {
            return;
          }

          this.pagination.total = data.total;
          this.attentions = docs;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    goToPatientDashboard(id) {
      helperService.callIfNoSelectedText(() =>
        this.$router.push({
          name: "patient-dashboard",
          params: { id },
        })
      );
    },

    getAttentionLabel(attention) {
      const { label } = ATTENTION_RISKS.find(
        (elem) => elem.value === attention.risk
      );

      return label;
    },

    getAttentionStatus(attention) {
      if (attention.doctor) {
        return "Derivado";
      } else {
        return "Pendiente";
      }
    },
  },
};
</script>

<template lang="pug">
  section.dashboard-alerts
    header.headline

      .headline__title
        h1
          | Solicitud de atenciones
          small(v-if="!isLoading && filteredAttentions.length")  ({{ filteredAttentions.length }})

        hr

      .headline__actions
        el-select(
          v-if="!this.isDoctor"
          v-model="filterBy"
          placeholder="Filtrar"
          default-first-option
          :disabled="isLoading"
          )
          el-option(
            key="pending"
            label="Pendientes"
            value="pending"
          )
          el-option(
            key="asigned"
            label="Asignados"
            value="asigned"
          )
          el-option(
            key="all"
            label="Todos"
            value="all"
          )

    .box.box--with-subnav
      spinner(v-if="isLoading")

      h2.no-alerts(v-if="!isLoading && !filteredAttentions.length") No hay resultados

      table(v-if="!isLoading && filteredAttentions.length")
        thead
          tr
            th Paciente
            th Estado
            th Última Actualización
            th Riesgo
            th(v-if="!isAdmin") Acciones
        tbody
          tr(v-for="attention in filteredAttentions", :key="attention.id" @click="goToPatientDashboard(attention.patient._id)")
            td {{ attention.patient.lastName }}, {{ attention.patient.firstName }}
            td {{ getAttentionStatus(attention) }}

            td Hace {{ attention.updatedAt | formatDistanceStrict }}
            td
              attention-tag(:type="attention.risk" :label="getAttentionLabel(attention)")
            td.actions(v-if="!isAdmin")
              .actions-container
                el-dropdown(trigger="click")
                  .button.button--action.el-dropdown-link(@click.stop)
                    micon(name="more_horiz")
                  el-dropdown-menu(slot="dropdown")
                      el-dropdown-item
                        div(@click="showModal(attention, 'attention-detail')")
                          micon(name="forum")
                          | Ver atención
                      //- el-dropdown-item(v-if="!attention.doctor")
                        div(@click="showModal(attention, 'assign-attention')")
                          micon(name="edit")
                          | Asignar atención

      ModalAttention(
        v-if="modal === 'attention-detail'"
        :attention='selectedAttention'
        :show-dialog-modal='!!selectedAttention' 
        @attention-assigned="attentionAssigned"
        @close='closeModal'
      )

      modal-assign-attention(
        v-if="modal === 'assign-attention'"
        :attention="selectedAttention"
        :showDialogModal="!!selectedAttention",
        @attention-assigned="attentionAssigned"
        @close="closeModal"
      )

    pagination(
      :isLoading="isLoading"
      :limit="pagination.limit"
      :total="pagination.total"
      @pagination="setPagination"
    )
</template>

<style lang="scss" scoped>
@import "@/styles/settings/index.scss";
@import "@/styles/tools/index.scss";

tbody td:hover {
  cursor: pointer;
}

.dashboard-alerts {
  .headline {
    @include media(tablet-up) {
      margin: 0 0 15px;
      .headline__actions {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;

        & > * {
          margin: 0 15px 15px 0;
          &:last-child {
            margin: 0;
          }
        }
      }
    }
    @include media(desktop-up) {
      .headline__title {
        margin: 0 15px 15px 0;
      }
      .headline__actions {
        width: 100%;
        justify-content: flex-end;
        & > .el-input,
        & > .el-select {
          width: 200px;
          margin: 0 0 0 15px;
          &:first-child {
            margin: 0;
          }
        }
      }
    }
    @include media(laptop-up) {
      margin: 0 0 30px;
      .headline__title {
        margin: 0 15px 0 0;
      }
      .headline__actions {
        flex: 1;
      }
    }
  }

  .box--with-subnav {
    @include media(mobile-up) {
      height: calc(100vh - 302px);
    }
    @include media(tablet-up) {
      height: calc(100vh - 332px);
    }
    @include media(desktop-up) {
      height: calc(100vh - 272px);
    }
    @include media(laptop-up) {
      height: calc(100vh - 232px);
    }
  }
}
</style>
