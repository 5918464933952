import VadiguAppointmentsView from "@/components/appointment/VadiguAppointmentsView";
import AppointmentsView from "@/components/appointment/AppointmentsView";
import AgendasView from "@/components/appointment/AgendasView";
import AdmissionsView from "@/components/appointment/AdmissionsView";
import AppointmentsRelocationView from "@/components/appointment/AppointmentsRelocationView";
// import { theme } from "@/services/config";

const appointmentsEngine = {
  albya: VadiguAppointmentsView,
  default: AppointmentsView,
};

const appointmentRoutes = [
  {
    path: "/appointment",
    name: "appointment",
    // component: appointmentsEngine[theme] || appointmentsEngine.default,
    component: appointmentsEngine.default,
  },
  {
    path: "/appointment/agendas",
    name: "appointment-agendas",
    component: AgendasView,
  },
  {
    path: "/appointment/relocation",
    name: "appointment-relocation",
    component: AppointmentsRelocationView,
  },
  {
    path: "/admissions",
    name: "admissions",
    component: AdmissionsView,
  },
];

export default appointmentRoutes;
