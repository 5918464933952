import api from "./api";

const organizationService = {};
const endpoint = () => "/organization";

organizationService.getConfiguration = function () {
  return api.get(`${endpoint()}/configuration`).then(({ data }) => data);
};

export default organizationService;
