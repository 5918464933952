<script>
export default {
  name: "MonitoringTag",

  props: {
    type: {
      type: String,
    },
    name: {
      type: String,
    },
    count: {
      type: Number,
    },
  },
};
</script>

<template lang="pug">
  .tag-with-count(v-if="count || type === 'without-checking'" :class="`${type}-tag`")
    .count(v-if="type !== 'without-checking'") {{ count > 99 ? '99+' : count}}
    el-tag(type="warning") {{ name }}
</template>

<style lang="scss" scoped>
.tag-with-count {
  position: relative;

  .count {
    position: absolute;
    top: -7px;
    right: 5px;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    font-size: 11px;
  }

  .el-tag {
    margin-right: 10px;
    padding: 0 5px;
  }

  &.red-tag {
    .el-tag,
    .count {
      background-color: #fef0f0;
      border-color: #fde2e2;
      border: 1px solid #fde2e2;
      color: #f56c6c;
    }
  }

  &.yellow-tag {
    .el-tag,
    .count {
      background-color: #fdf6ec;
      border-color: #faecd8;
      border: 1px solid #faecd8;
      color: #e6a23c;
    }
  }

  &.green-tag {
    .el-tag,
    .count {
      background-color: #f0f9eb;
      border-color: #e1f3d8;
      border: 1px solid #e1f3d8;
      color: #67c23a;
    }
  }

  &.incomplete-tag {
    .el-tag,
    .count {
      background-color: #ecf5ff;
      border-color: #d9ecff;
      border: 1px solid #d9ecff;
      color: #409eff;
    }
  }

  &.without-checking-tag {
    .el-tag {
      background-color: #e4e3e1;
      border-color: #d2d1cf;
      border: 1px solid #d2d1cf;
      color: #8c8b89;
    }
  }
}
</style>
