<script>
import helperService from "@/services/helper";

export default {
  name: "DoctorsDetail",

  props: {
    doctors: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  methods: {
    getPatientDoctorsNames(doctors) {
      return helperService.getPatientDoctorsNames(doctors);
    },

    getFullName(user) {
      return helperService.getFullName(user);
    },
  },

  computed: {
    hasDoctorsAsigned() {
      return this.doctors.length;
    },

    doctorsTooltip() {
      return this.doctors.reduce((acc, doctor) => {
        return `${acc}${this.getFullName(doctor)}<br>`;
      }, "");
    },
  },
};
</script>

<template lang="pug">
  span(v-tooltip.bottom="this.doctors.length > 1 ? doctorsTooltip : null") {{ hasDoctorsAsigned ? getPatientDoctorsNames(doctors) : 'Sin Médico' }}
</template>
